import { useEffect, useState } from "react";
import toast from "react-hot-toast";

// services
import { createProdi, updateProdi, destroyProdi } from "services/Admin";
import { confirmModal, getProdis, successModal } from "services/general";

// components
import TFade from "components/Transition/Fade";
import Header from "components/Header";
import NotFound from "components/NotFound";
import Button from "components/Button";
import InputText from "components/Form/InputText";
import Modal from "components/Modal";

// assets
import ic_prodi from "assets/icons/ic_prodi.svg";
import ic_full_screen from "assets/icons/ic_full_screen.svg";
import ic_chevron_down from "assets/icons/ic_chevron_down.svg";

const Dashboard = () => {
  const [prodis, setProdis] = useState([]);
  const [filteredProdi, setFilteredProdi] = useState([]);
  const [search, setSearch] = useState("");

  const [id, setId] = useState("");
  const [name, setName] = useState("");

  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);

  const openFullScreen = () => {
    const elem = document.documentElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const resetForm = () => {
    setId("");
    setName("");
  };

  const getData = async () => {
    try {
      const response = await getProdis();
      setProdis(response.data.body.data.prodis);
      setFilteredProdi(response.data.body.data.prodis);
    } catch (error) {
      console.error(error);
    }
  };

  const addProdi = (e) => {
    e.preventDefault();

    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await createProdi({
            name: name,
          });
          setShowModalAdd(false);
          resetForm();
          successModal("Success", "Data berhasil disimpan!");
          getData();
        } catch (error) {
          toast.error(error.response.data.body.message);
          console.error(error);
        }
      }
    });
  };

  const editProdi = (e) => {
    e.preventDefault();

    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await updateProdi(
            {
              name: name,
            },
            id
          );
          setShowModalEdit(false);
          resetForm();
          successModal("Success", "Data berhasil disimpan!");
          getData();
        } catch (error) {
          toast.error(error.response.data.body.message);
          console.error(error);
        }
      }
    });
  };

  const deleteProdi = (prodiId) => {
    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await destroyProdi(prodiId);
          resetForm();
          successModal("Success", "Data berhasil dihapus!");
          getData();
        } catch (error) {
          toast.error(error.response.data.body.message);
          console.error(error);
        }
      }
    });
  };

  const filterBySearch = (query) => {
    var updatedList = [...prodis];
    updatedList = updatedList.filter((item) => {
      return item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });

    setSearch(query);
    setFilteredProdi(updatedList);
  };

  return (
    <>
      <TFade>
        <div className="mb-5 flex flex-wrap items-center justify-between gap-3">
          <Header
            icon={<img src={ic_prodi} alt="Icon Calendar" />}
            title="Kelola Prodi"
          />

          <div
            className="flex cursor-pointer items-center gap-3"
            onClick={openFullScreen}
          >
            <img src={ic_full_screen} alt="Icon Full Screen" />
            <span className="text-lg font-medium">Full Screen</span>
          </div>
        </div>

        <div className="mb-5 flex gap-3">
          <div className="w-full">
            <InputText
              name="search"
              placeholder="Cari berdasarkan nama"
              value={search}
              onChange={(e) => filterBySearch(e.target.value)}
              onDelete={() => setSearch("")}
            />
          </div>
          <div>
            <div
              className="card card-form cursor-pointer !bg-[#FFEFC7]"
              onClick={() => {
                resetForm();
                setShowModalAdd(true);
              }}
            >
              <svg
                width="31"
                height="30"
                viewBox="0 0 31 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_225_5)">
                  <path
                    d="M22.0684 15.9031C22.9128 15.8588 23.7079 15.7154 24.4806 15.7943C27.9718 16.1509 30.5791 18.82 30.9338 22.3183C31.253 25.4668 29.6871 28.0573 26.7425 29.2024C24.5487 30.0555 22.3359 30.1904 20.1245 29.2553C18.5882 28.6056 17.3947 27.5851 16.7119 26.0269C16.6778 25.9496 16.6297 25.8775 16.5787 25.7858C15.9318 25.8206 15.2978 25.8563 14.6637 25.8888C11.1711 26.0669 7.67904 26.2558 4.18567 26.4139C3.24236 26.4565 2.29519 26.4108 1.34918 26.3916C1.12982 26.3857 0.912316 26.3495 0.702826 26.2841C0.0834729 26.0932 -0.122283 25.6647 0.0949277 25.0593C1.02757 22.4166 2.46253 19.9796 4.32056 17.8831C6.58758 15.3337 9.35372 13.726 12.7827 13.3739C15.1286 13.1332 17.3814 13.5275 19.5472 14.4426C20.0178 14.6258 20.4765 14.838 20.921 15.078C21.3178 15.3323 21.7008 15.6077 22.0684 15.9031ZM20.3288 16.8203C18.5008 15.9986 16.7334 15.3211 14.7759 15.2307C12.0526 15.1049 9.58829 15.7908 7.4356 17.47C5.12216 19.2747 3.42741 21.5927 2.05714 24.164C1.99782 24.296 1.94743 24.4317 1.9063 24.5704C3.14912 24.683 15.529 23.8376 16.3109 23.5887C16.3304 23.4971 16.3594 23.3925 16.3738 23.2862C16.5657 21.9765 16.9979 20.7136 17.6485 19.5613C17.8669 19.1805 18.1389 18.8333 18.4562 18.5302C19.0319 17.9632 19.6486 17.4368 20.329 16.8203H20.3288ZM23.3818 27.596C24.4407 27.5893 25.486 27.355 26.4468 26.9088C27.176 26.5842 27.7868 26.0413 28.195 25.3546C29.1405 23.7563 28.9584 21.1712 27.7621 19.7503C26.8089 18.6181 25.6178 17.8361 24.115 17.7122C22.3716 17.5687 20.8901 18.1862 19.8831 19.6315C18.8745 21.0792 18.6313 22.7553 18.8166 24.4884C18.9029 25.1974 19.2563 25.8467 19.8046 26.3038C20.8245 27.2118 22.0367 27.5884 23.3821 27.596H23.3818Z"
                    fill="#101010"
                  />
                  <path
                    d="M13.1974 11.8401C11.7024 11.7787 10.238 11.4681 8.95085 10.5537C7.49687 9.52085 6.34895 7.59904 7.2502 5.1993C7.47732 4.58081 7.78504 3.99509 8.16536 3.45727C8.6853 2.74646 9.25918 2.07682 9.88194 1.45433C11.2837 0.0380242 13.6758 -0.313088 15.3651 0.724552C17.5809 2.08511 18.8684 4.12843 19.1215 6.70727C19.2719 8.23791 18.591 9.54223 17.3762 10.5155C16.1712 11.4808 14.7533 11.8058 13.1974 11.8401ZM12.9969 9.88856C13.9322 9.865 14.8415 9.75279 15.6563 9.25051C16.7649 8.56734 17.2124 7.44316 16.8796 6.18767C16.6199 5.20629 16.086 4.38197 15.3994 3.65386C14.7153 2.92845 13.915 2.4384 12.8737 2.40685C11.7273 2.37209 10.8596 2.9882 10.061 3.65927C9.47582 4.15099 9.12125 4.86416 8.88256 5.6121C8.35323 7.2706 8.93235 8.61947 10.5065 9.37179C11.2961 9.74994 12.141 9.85265 12.9969 9.88844V9.88856Z"
                    fill="#101010"
                  />
                  <path
                    d="M24.6373 23.4537C24.6373 24.0787 24.6472 24.5845 24.6327 25.0898C24.6295 25.3091 24.5944 25.5265 24.5287 25.7357C24.4872 25.8752 24.4011 25.9971 24.2835 26.0827C24.166 26.1684 24.0235 26.2129 23.8782 26.2094C23.7327 26.2178 23.5888 26.1763 23.47 26.092C23.3512 26.0076 23.2646 25.8852 23.2245 25.745C23.1575 25.4915 23.1235 25.2304 23.1235 24.9682C23.1085 24.4897 23.1193 24.0102 23.1193 23.459C22.5344 23.459 21.9894 23.4738 21.4458 23.4536C21.0949 23.4456 20.7458 23.4027 20.4034 23.326C20.253 23.2921 20.1182 23.2087 20.0209 23.0889C19.9236 22.9692 19.8692 22.8202 19.8668 22.6658C19.8643 22.5115 19.9137 22.3608 20.0072 22.238C20.1007 22.1151 20.2327 22.0275 20.382 21.9888C20.6564 21.9138 20.9393 21.8736 21.2238 21.8694C21.8336 21.8522 22.4444 21.8641 23.0786 21.8641C23.0786 21.2427 23.0759 20.6981 23.0801 20.1537C23.0704 19.9567 23.0825 19.7593 23.1161 19.565C23.1804 19.2913 23.3415 19.0828 23.6599 19.0796C23.7943 19.0698 23.9277 19.1086 24.036 19.1887C24.1444 19.2689 24.2204 19.3853 24.2505 19.5167C24.3333 19.8807 24.3921 20.2499 24.4264 20.6217C24.4721 21.0097 24.4955 21.4002 24.5293 21.8048C24.9133 21.8048 25.2389 21.8141 25.5637 21.8023C25.9374 21.7888 26.3103 21.7385 26.6837 21.7362C27.28 21.7326 27.6562 22.0206 27.6891 22.4721C27.7198 22.8948 27.4117 23.2407 26.8584 23.3319C26.4235 23.3938 25.9856 23.4335 25.5467 23.4506C25.2662 23.4675 24.984 23.4537 24.6373 23.4537Z"
                    fill="#101010"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_225_5">
                    <rect width="30.9934" height="30" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>

        <div className="card relative !block rounded-2xl">
          <table class="w-full table-auto">
            <thead>
              <tr>
                <th className="rounded-l-xl">#</th>
                <th>Nama</th>
                <th className="rounded-r-xl">Aksi</th>
              </tr>
            </thead>
            <tbody>
              {filteredProdi.length > 0 ? (
                filteredProdi.map((prodi, index) => (
                  <>
                    <tr key={`prodi-${index}`}>
                      <td>{index + 1}</td>
                      <td>{prodi.name}</td>
                      <td>
                        <div className="flex gap-3">
                          <Button
                            className="flex h-12 w-12 items-center justify-center !rounded-lg !p-2"
                            isDanger
                            onClick={() => deleteProdi(prodi.id)}
                          >
                            <svg
                              width="28"
                              height="25"
                              viewBox="0 0 28 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.6546 24.8515C11.4498 24.9797 10.0701 24.7148 7.86312 24.6354C7.43415 24.5975 7.02849 24.424 6.70525 24.1402C4.28538 21.7695 2.43482 18.9493 0.551444 16.1645C-0.147604 15.1309 0.0404096 14.2884 1.01747 13.4674C5.01876 10.1054 11.3184 4.64377 15.3698 1.26311C15.4528 1.19405 15.5925 1.08536 15.681 1.02238C17.5389 -0.297509 18.1024 -0.256762 19.4469 1.52445C20.7235 3.2163 21.9197 4.96795 23.1807 6.67154C24.0501 7.84557 24.9681 8.98438 25.8681 10.136C26.0707 10.3952 26.3156 10.6246 26.4945 10.8979C27.3281 12.1725 27.2006 13.0603 26.0506 14.0627C23.7333 16.0823 19.0937 20.2537 16.7781 22.2753C16.4878 22.5282 14.4297 24.8062 13.6546 24.8515ZM24.3645 12.9391C22.0465 9.24543 19.4986 5.73231 17.2322 2.12099C14.7581 4.08231 10.4181 8.11726 8.06971 9.97941L17.0214 19.479C18.741 17.9798 22.7301 14.3642 24.3645 12.9391ZM14.9528 21.3425C12.0815 18.0691 9.70926 14.3565 6.49965 11.3073L2.24138 15.0304C3.65582 17.0916 4.88502 19.1365 6.36591 20.9801C8.48202 23.6145 7.45968 23.0394 10.8436 23.3367C11.775 23.4186 13.2657 23.2006 13.2657 23.2006C13.2657 23.2006 14.4982 22.2113 14.9528 21.3425Z"
                                fill="#EA4335"
                              />
                            </svg>
                          </Button>
                          <Button
                            className="flex h-12 w-12 items-center justify-center !rounded-lg !p-2"
                            isInfo
                            onClick={() => {
                              setId(prodi.id);
                              setName(prodi.name);
                              setShowModalEdit(true);
                            }}
                          >
                            <svg
                              width="26"
                              height="24"
                              viewBox="0 0 26 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.008783 23.1668C0.0219752 23.07 0.0400849 22.9741 0.0630481 22.8793C0.405559 21.6682 0.75878 20.4601 1.08964 19.2458C1.12065 19.1057 1.18017 18.9736 1.26447 18.8577C1.34877 18.7417 1.45602 18.6446 1.57953 18.5721C1.7026 18.4992 1.81585 18.4108 1.91648 18.309C5.85488 14.3048 10.0552 10.582 14.1957 6.79465C15.9333 5.20517 17.6097 3.54845 19.3412 1.95192C19.9986 1.34571 20.7228 0.810122 21.4353 0.267175C21.821 -0.0268254 22.0521 -0.0269236 22.4097 0.269076C23.2404 0.95655 24.0659 1.65234 24.8629 2.37845C25.3822 2.8515 25.3906 3.37075 24.8996 3.87686C24.1993 4.59854 23.4643 5.28633 22.7441 5.98865C22.667 6.06391 22.5867 6.13601 22.5132 6.21549C19.9033 9.05359 17.0504 11.6407 14.2197 14.2481C12.1833 16.1242 10.0762 17.9236 8.0083 19.7658C7.41764 20.2921 6.84955 20.8439 6.27821 21.3914C5.45314 22.1964 4.44406 22.7866 3.33911 23.1104C2.49527 23.3562 1.66413 23.6472 0.816199 23.8768C0.277608 24.0233 -0.0217627 23.7432 0.008783 23.1668ZM19.608 3.34371C19.2521 3.63897 18.9121 3.90296 18.5919 4.18928C14.6775 7.68956 10.7639 11.191 6.85134 14.6935C5.52622 15.8829 4.27392 17.1514 3.10125 18.4921C2.95177 18.662 2.817 18.8451 2.66605 19.0339L3.25681 19.6771C9.198 14.6009 14.7643 9.15076 20.7851 4.07803L19.608 3.34371ZM4.62381 21.2212C7.25399 19.3511 20.7652 6.73812 21.8596 5.12802L21.4502 4.64382C21.3046 4.75739 21.1878 4.83854 21.0818 4.93275C17.5245 8.10328 13.959 11.2644 10.4157 14.4507C8.30357 16.3501 6.25049 18.3168 3.99063 20.045C3.90748 20.0999 3.821 20.1497 3.73169 20.1939L4.62381 21.2212ZM20.5563 2.31317L22.6976 4.30182L23.9888 3.19539L21.9859 1.2259L20.5563 2.31317ZM1.73719 19.9292C1.62855 20.399 1.54058 20.7275 1.48222 21.0613C1.46372 21.1813 1.48716 21.304 1.54856 21.4087C1.74286 21.7078 1.96277 21.9904 2.19023 22.3034L3.27298 21.8778L1.73719 19.9292Z"
                                fill="#00C2FF"
                              />
                            </svg>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  </>
                ))
              ) : (
                <tr>
                  <td colSpan={3}>
                    <NotFound />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </TFade>
      <Modal
        name="Tambah Data Prodi"
        showModal={showModalAdd}
        setShowModal={(value) => setShowModalAdd(value)}
        body={
          <form onSubmit={addProdi}>
            <div className="mt-6 grid grid-cols-1 gap-4">
              <InputText
                name="name"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onDelete={() => setName("")}
              />
            </div>

            <div className="mt-8 flex justify-end">
              <Button isPrimary type="submit" className="!px-10">
                Kirim
              </Button>
            </div>
          </form>
        }
      />
      <Modal
        name="Ubah Data Prodi"
        showModal={showModalEdit}
        setShowModal={(value) => setShowModalEdit(value)}
        body={
          <form onSubmit={editProdi}>
            <div className="mt-6 grid grid-cols-1 gap-4">
              <InputText
                name="name"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onDelete={() => setName("")}
              />
            </div>

            <div className="mt-8 flex justify-end">
              <Button isPrimary type="submit" className="!px-10">
                Kirim
              </Button>
            </div>
          </form>
        }
      />
    </>
  );
};

export default Dashboard;
