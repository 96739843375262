import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import moment from "moment";
import Calendar from "react-calendar";

// services
import {
  checkSchedule,
  getReservation,
  rescheduleReservation,
} from "services/Reservations";
import {
  StatusReservation,
  confirmModal,
  descriptionReservation,
  statusReservation,
  stepReservation,
  successModal,
} from "services/general";

// components
import TFade from "components/Transition/Fade";
import ArrowBack from "components/ArrowBack";
import InputText from "components/Form/InputText";
import Header from "components/Header";
import InputTextArea from "components/Form/InputTextArea";
import InputFileView from "components/Form/InputFileView";
import InputSelect from "components/Form/InputSelect";
import Button from "components/Button";
import Modal from "components/Modal";

// assets
import ic_calendar_small from "assets/icons/ic_calendar_small.svg";
import ic_male from "assets/icons/ic_male.svg";
import ic_microscope from "assets/icons/ic_microscope.svg";
import ic_suitcase from "assets/icons/ic_suitcase.svg";
import ic_folder from "assets/icons/ic_folder.svg";
import ic_happy from "assets/icons/ic_happy.svg";
import ic_cry from "assets/icons/ic_cry.svg";
import ic_sync from "assets/icons/ic_sync.svg";
import bgCircleYellow from "assets/images/bg-circle-yellow.svg";
import bgCircleRed from "assets/images/bg-circle-red.svg";
import bgCircleGreen from "assets/images/bg-circle-green.svg";
import ic_calendar from "assets/icons/ic_calendar.svg";
import ic_mic from "assets/icons/ic_mic.svg";

import "react-calendar/dist/Calendar.css";

const ScheduleDetail = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const steps = [
    {
      name: "Verifikasi Dokumen oleh SBA",
    },
    {
      name: "Pemilihan Dosen Penguji oleh Prodi",
    },
    {
      name: "Verifikasi Dosen Penguji 1 & 2",
    },
  ];
  const [currentStep, setCurrentStep] = useState(4);
  const [data, setData] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [date, setDate] = useState(new Date());
  const [session, setSession] = useState("");
  const [hasRoom, setHasRoom] = useState(null);

  const fieldsSession = [
    {
      name: "Waiting List",
      value: 0,
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await getReservation(searchParams.get("id"));
      setData(response.data.body.data.reservation);
      setCurrentStep(stepReservation(response.data.body.data.reservation));
    } catch (error) {
      console.error(error);
    }
  };

  const renderHeader = () => {
    const status = statusReservation(data);

    switch (status) {
      case StatusReservation.accepted:
        return (
          <div className="relative z-0 mt-10 mb-6 flex flex-col justify-center overflow-hidden rounded-2xl bg-light-green px-8 py-10">
            <img
              src={bgCircleGreen}
              alt="Background Circle"
              className="absolute right-0 bottom-0 -z-10"
            />

            <h2 className="text-3xl font-semibold">
              {descriptionReservation(data)}
            </h2>

            <p>
              Reservasi jadwal ruangan sidang kamu telah disetujui, silahkan cek
              email kamu untuk informasi lebih lanjut.
            </p>
          </div>
        );
      case StatusReservation.canceled:
        return (
          <div className="relative z-0 mt-10 mb-6 flex flex-col justify-center overflow-hidden rounded-2xl bg-light-red px-8 py-10">
            <img
              src={bgCircleRed}
              alt="Background Circle"
              className="absolute right-0 bottom-0 -z-10"
            />

            <h2 className="text-3xl font-semibold">
              {descriptionReservation(data)}
            </h2>

            <p>
              Reservasi jadwal ruangan sidang kamu telah ditolak, silahkan cek
              email kamu untuk informasi lebih lanjut.
            </p>

            <div className="mt-5">
              <Button
                onClick={() => setShowModal(true)}
                isWhite
                className="flex items-center gap-3"
              >
                <img src={ic_sync} alt="Icon Sync" />
                Reservasi Ulang Jadwal
              </Button>
            </div>
          </div>
        );
      default:
        return (
          <div className="relative z-0 mt-10 mb-6 flex flex-col justify-center overflow-hidden rounded-2xl bg-cream px-8 py-10">
            <img
              src={bgCircleYellow}
              alt="Background Circle"
              className="absolute right-0 bottom-0 -z-10"
            />

            <h2 className="text-3xl font-semibold">
              {descriptionReservation(data)}
            </h2>

            <p>
              Reservasi jadwal ruangan sidang kamu sedang diproses, mohon tunggu
              sampai <br /> keputusan akhir keluar.
            </p>
          </div>
        );
    }
  };

  const checkRoom = async () => {
    try {
      const data = await checkSchedule({
        date: moment(date).format("YYYY-MM-DD"),
        session,
      });

      if (data.data.body.status == true) {
        setHasRoom(true);
        toast.success(
          "Ruangan tersedia, kamu akan dimasukan kedalam waiting list."
        );
      } else {
        setHasRoom(false);
        toast.error("Ruangan tidak tersedia, pilih tanggal atau sesi lainnya.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const reschedule = (e) => {
    e.preventDefault();

    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await rescheduleReservation(searchParams.get("id"), {
            date: moment(date).format("YYYY-MM-DD"),
            session,
          });
          setShowModal(false);
          successModal("Success", "Jadwal reservasi berhasil diubah!");
          navigate("/mahasiswa/dashboard");
        } catch (error) {
          toast.error(error.response.data.body.message);
          console.error(error);
        }
      }
    });
  };

  return (
    <>
      <TFade>
        <ArrowBack />

        {data && (
          <>
            {renderHeader()}

            <ul className="step-wizard-list mb-6">
              {steps.map((step, index) => (
                <li
                  key={`step-${index}`}
                  className={`step-wizard-item ${
                    currentStep > index + 1
                      ? "success"
                      : currentStep === index + 1
                      ? "process"
                      : ""
                  }`}
                >
                  <div className="flex flex-col items-center">
                    <span className="progress-count">{index + 1}</span>
                    <span className="progress-label hidden w-36 text-center lg:block">
                      {step.name}
                    </span>
                  </div>
                </li>
              ))}
              <li
                className={`step-wizard-item ${
                  currentStep === 4 ? "success" : ""
                }`}
              >
                <div className="flex flex-col items-center">
                  <span className="progress-count">
                    <img src={ic_happy} alt="Icon Happy" />
                  </span>
                  <span className="progress-label hidden w-36 text-center lg:block">
                    Reservasi Kamu Disetujui
                  </span>
                </div>
              </li>

              <li
                className={`step-wizard-item ${
                  currentStep === 5 ? "failed" : ""
                }`}
              >
                <div className="flex flex-col items-center">
                  <span className="progress-count">
                    <img src={ic_cry} alt="Icon Cry" />
                  </span>
                  <span className="progress-label hidden w-36 text-center lg:block">
                    Reservasi Kamu Ditolak
                  </span>
                </div>
              </li>
            </ul>

            <Header
              icon={<img src={ic_calendar_small} alt="Icon Calendar" />}
              title="Tanggal Reservasi Ruangan"
            />

            <div className="mt-6 grid gap-5 md:grid-cols-2">
              <div>
                <h5 className="mb-3 text-lg font-medium">Tanggal Sidang</h5>

                <InputText
                  name="date"
                  placeholder="Tanggal Sidang"
                  value={data.date}
                  disabled
                />
              </div>

              <div>
                <h5 className="mb-3 text-lg font-medium">Sesi Sidang</h5>

                <InputText
                  name="session"
                  placeholder="Sesi Sidang"
                  value={
                    data.session === 0 ? "Waiting List" : `Sesi ${data.session}`
                  }
                  disabled
                />
              </div>
            </div>

            <Header
              icon={<img src={ic_male} alt="Icon Male" />}
              title="Identitas Diri"
              className="mt-10"
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Nama Lengkap(sesuai Akta Kelahiran)
            </h5>

            <InputText
              name="name"
              placeholder="Name"
              value={data.name_student}
              disabled
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">NPM</h5>

            <InputText
              name="npm"
              type="number"
              placeholder="NPM"
              value={data.npm_student}
              disabled
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">Alamat</h5>

            <InputTextArea
              name="address"
              placeholder="Address"
              value={data.address_student}
              disabled
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              No. Tlp yang Dapat Dihubungi
            </h5>

            <InputText
              name="phoneNumber"
              type="number"
              placeholder="Nomor Telepon"
              value={data.phone_student}
              disabled
            />

            <Header
              icon={<img src={ic_microscope} alt="Icon Microscope" />}
              title="Informasi Bimbingan"
              className="mt-10"
            />

            <div className="mt-6 grid gap-5 md:grid-cols-2">
              <div>
                <h5 className="mb-3 text-lg font-medium">
                  Tanggal Mulai Bimbingan
                </h5>

                <InputText
                  name="guidanceStartDate"
                  value={data.date_start_guidance}
                  placeholder="Tanggal Mulai Bimbingan"
                  disabled
                />
              </div>

              <div>
                <h5 className="mb-3 text-lg font-medium">
                  Tanggal Akhir Bimbingan
                </h5>

                <InputText
                  name="guidanceEndDate"
                  value={data.date_end_guidance}
                  placeholder="Tanggal Akhir Bimbingan"
                  disabled
                />
              </div>

              <div>
                <h5 className="mb-3 text-lg font-medium">
                  Prodi Dosen Pembimbing
                </h5>

                <InputText
                  name="guidanceStudyProgram"
                  value={data.lecture_guide?.prodi.name}
                  disabled
                />
              </div>

              <div>
                <h5 className="mb-3 text-lg font-medium">Dosen Pembimbing</h5>

                <InputText
                  name="guidanceLecturer"
                  value={data.lecture_guide.name}
                  disabled
                />
              </div>
            </div>

            <Header
              icon={<img src={ic_suitcase} alt="Icon Suitcase" />}
              title="Informasi skripsi"
              className="mt-10"
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Judul Skripsi Bahasa Indonesia
            </h5>

            <InputText
              name="titleId"
              placeholder="Judul Skripsi Bahasa Indonesia"
              value={data.title_skripsi_indonesia}
              disabled
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Judul Skripsi Bahasa Inggris
            </h5>

            <InputText
              name="titleEn"
              placeholder="Judul Skripsi Bahasa Inggris"
              value={data.title_skripsi_english}
              disabled
            />

            <Header
              icon={<img src={ic_folder} alt="Icon Folder" />}
              title="Berkas Persyaratan"
              className="mt-10"
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Melampirkan Surat Tugas dan Jadwal Bimbingan Skripsi yang sudah
              disetujui Pembimbing dan ditandatangani Ketua Program Studi
            </h5>

            <InputFileView
              name="suratTugasJadwalBimbingan"
              value={data.surat_tugas_jadwal_bimbingan}
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Melampirkan lembar validasi similarity pengecekan Turnitin skripsi
              yang ditandatangan petugas perpustakaan
            </h5>

            <InputFileView
              name="validasiSimilarity"
              value={data.lembar_validasi_similarity}
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Transkrip Akademik yang sudah ditandatangani oleh Wakil Dekan I
            </h5>

            <InputFileView
              name="transkripAkademik"
              value={data.transkrip_akademik}
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Tanda Bukti Pembayaran Uang Kuliah/Registrasi Terakhir
            </h5>

            <InputFileView
              name="pembayaranUangKuliah"
              value={data.bukti_pembayaran_kuliah}
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">KRS Terakhir</h5>

            <InputFileView name="krsTerakhir" value={data.krs_terakhir} />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Menyerahkan Draft Skripsi yang telah ditanda tangani oleh
              pembimbing sebanyak 4 eksemplar bagi 1 Pembimbing dan 5 eksemplar
              bagi 2 Pembimbing dengan melampirkan Surat Pernyataan Keaslian
              Karya Ilmiah bermaterai Rp 6000,-
            </h5>

            <InputFileView name="draftSkripsi" value={data.draft_skripsi} />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Menyerahkan File Presentasi Sidang Skripsi
            </h5>

            <InputFileView name="filePresentasi" value={data.file_presentasi} />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Menyerahkan lembar selesai revisi dari Seminar Usulan Penelitian
              Skripsi yang ditandatangan Pembimbing
            </h5>

            <InputFileView
              name="selesaiRevisi"
              value={data.lembar_selesai_revisi}
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Menyerahkan sedikitnya 2 (dua) buah foto bukti menghadiri Seminar
              UP, Sidang Skripsi atau Diseminasi Paper Terpublikasi
            </h5>

            <InputFileView
              name="buktiMenghadiri"
              value={data.foto_bukti_seminar}
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Pas Photo Ukuran 4x6
            </h5>

            <InputFileView name="foto4x6" value={data.pas_photo_4x6} />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Pas Photo Ukuran 3x4
            </h5>

            <InputFileView name="foto3x4" value={data.pas_photo_3x4} />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Pas Photo Ukuran 2x3
            </h5>

            <InputFileView name="foto2x3" value={data.pas_photo_2x3} />

            <h5 className="mt-6 mb-3 text-lg font-medium">KTM Asli</h5>

            <InputFileView name="ktm" value={data.ktm} />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Melampirkan Fotocopy Toefl dan memperlihatkan aslinya yang masih
              berlaku (Skor ≥ 500)
            </h5>

            <InputFileView name="toefl" value={data.toefl} />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Surat bebas Pinjam Buku dari Perpustakaan Universitas
            </h5>

            <InputFileView
              name="bebasPinjamBukuUniversitas"
              value={data.surat_bebas_pinjam_buku_universitas}
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Surat bebas Pinjam Buku dari Perpustakaan Fakultas
            </h5>

            <InputFileView
              name="bebasPinjamBukuFakultas"
              value={data.surat_bebas_pinjam_buku_fakultas}
            />
          </>
        )}
      </TFade>
      <Modal
        name="Rubah Jadwal Reservasi"
        showModal={showModal}
        setShowModal={(value) => setShowModal(value)}
        body={
          <form onSubmit={reschedule}>
            <Header
              icon={<img src={ic_calendar} alt="Icon Calendar" />}
              title="Tanggal Reservasi Ruangan"
            />

            <div className="card my-4 rounded-2xl">
              <Calendar
                onChange={(value) => setDate(value)}
                value={date}
                className="!w-full border-none"
              />
            </div>

            <h5 className="my-4 text-lg font-medium">Sesi Sidang</h5>

            <InputSelect
              name="session"
              value={session}
              fields={fieldsSession}
              onChange={(e) => setSession(e.target.value)}
              disabled
            />

            <div className="mt-5 flex flex-wrap items-center justify-end gap-3">
              <Button isPrimary type="submit" className="!px-10">
                Submit
              </Button>
            </div>
          </form>
        }
      />
    </>
  );
};

export default ScheduleDetail;
