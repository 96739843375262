import axios from "axios";
import moment from "moment";
import headers from "services/Headers";

export const checkSchedule = (form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/reservations/check-schedule`,
    form,
    headers()
  );
};

export const getLecturesByProdi = (prodi) => {
  return axios.post(
    `${process.env.REACT_APP_API}/reservations/list-lecture`,
    prodi,
    headers()
  );
};

export const getLecturesExaminerByProdi = (prodi) => {
  return axios.post(
    `${process.env.REACT_APP_API}/reservations/list-lecture-examiner`,
    prodi,
    headers()
  );
};

export const getReservationsStudent = () => {
  return axios.get(
    `${process.env.REACT_APP_API}/reservations/data-reservation/student`,
    headers()
  );
};

export const getReservationsGuidance = () => {
  return axios.get(
    `${process.env.REACT_APP_API}/reservations/data-reservation/guidance`,
    headers()
  );
};

export const getReservationsExaminer = () => {
  return axios.get(
    `${process.env.REACT_APP_API}/reservations/data-reservation/examiner`,
    headers()
  );
};

export const getReservationsAcademic = () => {
  return axios.get(
    `${process.env.REACT_APP_API}/reservations/data-reservation/academic`,
    headers()
  );
};

export const getReservationsProdi = () => {
  return axios.get(
    `${process.env.REACT_APP_API}/reservations/data-reservation/prodi`,
    headers()
  );
};

export const getReservationsAdmin = () => {
  return axios.get(
    `${process.env.REACT_APP_API}/reservations/data-reservation/admin`,
    headers()
  );
};

export const acceptReservationExaminer = (id) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/reservations/verification-examiner/${id}`,
    {},
    headers()
  );
};

export const acceptReservationAcademic = (id) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/reservations/verification-sba/${id}`,
    {},
    headers()
  );
};

export const cancelReservation = (id, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/reservations/cancel-reservation/${id}`,
    form,
    headers()
  );
};

export const chooseExaminer = (id, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/reservations/choose-examiner/${id}`,
    form,
    headers()
  );
};

export const getReservation = (id) => {
  return axios.get(
    `${process.env.REACT_APP_API}/reservations/detail/${id}`,
    headers()
  );
};

export const getReservationsCalendar = () => {
  return axios.get(
    `${process.env.REACT_APP_API}/reservations/calendar`,
    headers()
  );
};

export const getReservationsRecap = (start, end) => {
  const dateStart = moment(start).format("YYYY-MM-DD");
  const dateEnd = moment(end).format("YYYY-MM-DD");

  return axios.get(
    `${process.env.REACT_APP_API}/reservations/recap?date_from=${dateStart}&date_to=${dateEnd}`,
    headers()
  );
};

export const rescheduleReservation = (id, form) => {
  return axios.post(
    `${process.env.REACT_APP_API}/reservations/reschedule/${id}`,
    form,
    headers()
  );
};

export const changeDateReservation = (id, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/reservations/change-date/${id}`,
    form,
    headers()
  );
};

export const changeSessionReservation = (id, form) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/reservations/change-session/${id}`,
    form,
    headers()
  );
};

export const createReservation = (form) => {
  const formData = new FormData();
  formData.append("date", moment(form.date).format("YYYY-MM-DD"));
  formData.append("session", form.session);
  formData.append("name_student", form.name);
  formData.append("npm_student", form.npm);
  formData.append("height_student", 0);
  formData.append("address_student", form.address);
  formData.append("phone_student", form.phoneNumber);
  formData.append(
    "date_start_guidance",
    moment(form.guidanceStartDate).format("YYYY-MM-DD")
  );
  formData.append(
    "date_end_guidance",
    moment(form.guidanceEndDate).format("YYYY-MM-DD")
  );
  formData.append("prodi_lecture_guidance", form.guidanceStudyProgram);
  formData.append("lecture_guidance", form.guidanceLecturer);
  formData.append("title_skripsi_indonesia", form.titleId);
  formData.append("title_skripsi_english", form.titleEn);
  formData.append(
    "surat_tugas_jadwal_bimbingan",
    form.suratTugasJadwalBimbingan
  );
  formData.append("lembar_validasi_similarity", form.validasiSimilarity);
  formData.append("transkrip_akademik", form.transkripAkademik);
  formData.append("bukti_pembayaran_kuliah", form.pembayaranUangKuliah);
  formData.append("krs_terakhir", form.krsTerakhir);
  formData.append("draft_skripsi", form.draftSkripsi);
  formData.append("file_presentasi", form.filePresentasi);
  formData.append("lembar_selesai_revisi", form.selesaiRevisi);
  formData.append("foto_bukti_seminar", form.buktiMenghadiri);
  formData.append("pas_photo_4x6", form.foto4x6);
  formData.append("pas_photo_3x4", form.foto3x4);
  formData.append("pas_photo_2x3", form.foto2x3);
  formData.append("ktm", form.ktm);
  formData.append("toefl", form.toefl);
  formData.append(
    "surat_bebas_pinjam_buku_universitas",
    form.bebasPinjamBukuUniversitas
  );
  formData.append(
    "surat_bebas_pinjam_buku_fakultas",
    form.bebasPinjamBukuFakultas
  );

  return axios.post(
    `${process.env.REACT_APP_API}/reservations/create-booking`,
    formData,
    headers(true)
  );
};
