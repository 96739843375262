// components
import ArrowBack from "components/ArrowBack";
import Copyright from "components/Copyright";
import Logo from "components/Logo";
import ReservationsCalendar from "components/ReservationsCalendar";

const Calendar = () => {
  return (
    <div className="bg-grid flex flex-col justify-between">
      <div className="mt-10 flex w-full items-center justify-between mb-10">
        <ArrowBack />

        <div>
          <Logo />
        </div>
      </div>

      {/* Calendar */}
      <ReservationsCalendar />

      {/* Copyright */}
      <Copyright />
    </div>
  );
};

export default Calendar;
