import { useEffect, useState } from "react";

// services
import { getReservationsProdi } from "services/Reservations";

// components
import TFade from "components/Transition/Fade";
import Header from "components/Header";
import ReservationsCalendar from "components/ReservationsCalendar";
import ReservationItem from "components/ReservationItem";
import NotFound from "components/NotFound";

// assets
import ic_calendar from "assets/icons/ic_calendar.svg";
import ic_full_screen from "assets/icons/ic_full_screen.svg";
import ic_time from "assets/icons/ic_time.svg";

const Dashboard = () => {
  const [reservationsVerification, setReservationsVerification] = useState([]);
  const [reservationsRecent, setReservationsRecent] = useState([]);

  const openFullScreen = () => {
    const elem = document.documentElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await getReservationsProdi();
      setReservationsVerification(response.data.body.data.reservation);
      setReservationsRecent(response.data.body.data.reservation_recent);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <TFade>
      <div className="mb-5 flex flex-wrap items-center justify-between gap-3">
        <Header
          icon={<img src={ic_calendar} alt="Icon Calendar" />}
          title="Kalendar Reservasi Ruangan"
        />

        <div
          className="flex cursor-pointer items-center gap-3"
          onClick={openFullScreen}
        >
          <img src={ic_full_screen} alt="Icon Full Screen" />
          <span className="text-lg font-medium">Full Screen</span>
        </div>
      </div>

      {/* Calendar */}
      <ReservationsCalendar />

      <Header
        icon={<img src={ic_time} alt="Icon Time" />}
        title="Butuh Verifikasi"
        className="mt-5"
      />

      {reservationsVerification.length > 0 ? (
        reservationsVerification.map((reservation, index) => (
          <ReservationItem data={reservation} key={`reservation-${index}`} />
        ))
      ) : (
        <NotFound />
      )}

      <Header
        icon={<img src={ic_time} alt="Icon Time" />}
        title="Jadwal Sidang Terbaru"
      />

      {reservationsRecent.length > 0 ? (
        reservationsRecent.map((reservation, index) => (
          <ReservationItem data={reservation} key={`reservation-${index}`} />
        ))
      ) : (
        <NotFound />
      )}
    </TFade>
  );
};

export default Dashboard;
