import { useEffect, useState } from "react";

// services
import { getReservationsRecap } from "services/Reservations";
import { renderDescriptionReservation } from "services/general";

// components
import TFade from "components/Transition/Fade";
import InputDate from "components/Form/InputDate";
import NotFound from "components/NotFound";
import Button from "components/Button";

// assets
import ic_flag_id from "assets/icons/ic_flag_id.png";
import ic_flag_en from "assets/icons/ic_flag_en.png";
import ic_eye from "assets/icons/ic_password_show.svg";

const Report = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [reservations, setReservations] = useState([]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setStartDate(new Date(currentYear, 0, 1));
    setEndDate(new Date(currentYear, 11, 31));
  }, []);

  useEffect(() => {
    getData();
  }, [startDate, endDate]);

  const getData = async () => {
    try {
      const response = await getReservationsRecap(startDate, endDate);
      console.log(response.data.body.data.reservations);
      setReservations(response.data.body.data.reservations);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <TFade>
      <div className="mb-10 grid grid-cols-2 gap-5 ">
        <div>
          <h5 className="mb-3 text-lg font-medium">Tanggal Awal Filter</h5>

          <InputDate
            name="startDate"
            value={startDate}
            placeholder="Tanggal Awal Filter"
            onChange={(value) => setStartDate(value)}
          />
        </div>

        <div>
          <h5 className="mb-3 text-lg font-medium">Tanggal Akhir Filter</h5>

          <InputDate
            name="endDate"
            value={endDate}
            placeholder="Tanggal Akhir Filter"
            onChange={(value) => setEndDate(value)}
          />
        </div>
      </div>

      <div className="card relative !block overflow-scroll rounded-2xl">
        <table className="w-[100vw] table-auto">
          <thead>
            <tr>
              <th className="rounded-l-xl">Mahasiswa</th>
              <th>Judul Skripsi</th>
              <th>Dosen Pembimbing</th>
              <th className="w-[10vw]">Dosen Penguji 1 & 2</th>
              <th>Tanggal & Sesi</th>
              <th>Status Sidang</th>
              <th className="rounded-r-xl">Aksi</th>
            </tr>
          </thead>
          <tbody>
            {reservations.length > 0 ? (
              reservations.map((reservation, index) => (
                <>
                  <tr key={`reservation-${index}`}>
                    <td>
                      <div className="flex items-center gap-3">
                        <div className="h-[70px] w-[70px]">
                          <img
                            src={`${process.env.REACT_APP_STORAGE}avatars/${reservation.student?.avatar}`}
                            alt="Avatar"
                            className="</div>h-full w-full rounded-full border-2 border-accent-black bg-white object-cover p-1"
                          />
                        </div>

                        <div>
                          <h5 className="text-base font-semibold">
                            {reservation.student?.name}
                          </h5>
                          <span className="font-medium capitalize">
                            <span className="text-primary-yellow">
                              {reservation.student?.npm}
                            </span>{" "}
                            - {reservation.student?.prodi.name}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex items-center gap-3">
                        <img src={ic_flag_id} alt="Icon Flag ID" />
                        {reservation.title_skripsi_indonesia}
                      </div>

                      <div className="flex items-center gap-3">
                        <img src={ic_flag_en} alt="Icon Flag EN" />
                        {reservation.title_skripsi_english}
                      </div>
                    </td>
                    <td>{reservation.lecture_guide?.name}</td>
                    <td>
                      - {reservation.examiner_1?.name}
                      <br />- {reservation.examiner_2?.name}
                    </td>
                    <td>
                      {reservation.date} - Sesi {reservation.session}
                    </td>
                    <td>{renderDescriptionReservation(reservation)}</td>
                    <td>
                      <Button
                        type="link"
                        href={`/prodi/schedule/detail?id=${reservation.id}`}
                        isInfo
                        className="flex h-12 w-12 items-center justify-center !rounded-lg !p-2"
                      >
                        <img src={ic_eye} alt="Icon Eye" />
                      </Button>
                    </td>
                  </tr>
                </>
              ))
            ) : (
              <tr>
                <td colSpan={5}>
                  <NotFound />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </TFade>
  );
};

export default Report;
