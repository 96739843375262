import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { PageTransition } from "@steveeeie/react-page-transition";

// layouts
import Default from "layouts/Default";
import Admin from "layouts/Admin";
import ScrollToTop from "components/ScrollToTop";

// middleware
import AuthorizeUser from "middleware/Auth";

// pages

// boarding
import Boarding from "pages/Boarding";
import Calendar from "pages/Calendar";

// auth
import Login from "pages/Auth/Login";
import Register from "pages/Auth/Register";
import LoginOther from "pages/Auth/LoginOther";
import Activation from "pages/Auth/Activation";

// mahasiswa
import MahasiswaDashboard from "pages/Mahasiswa/Dashboard";
import MahasiswaSchedule from "pages/Mahasiswa/Schedule";
import MahasiswaScheduleDetail from "pages/Mahasiswa/Schedule/Detail";
import MahasiswaNotification from "pages/Mahasiswa/Notification";
import MahasiswaProfile from "pages/Mahasiswa/Profile";

// dosen
import DosenDashboard from "pages/Dosen/Dashboard";
import DosenSchedule from "pages/Dosen/Schedule";
import DosenNotification from "pages/Dosen/Notification";
import DosenProfile from "pages/Dosen/Profile";

// sba
import SBADashboard from "pages/SBA/Dashboard";
import SBASchedule from "pages/SBA/Schedule";
import SBANotification from "pages/SBA/Notification";
import SBAProfile from "pages/SBA/Profile";

// prodi
import ProdiDashboard from "pages/Prodi/Dashboard";
import ProdiSchedule from "pages/Prodi/Schedule";
import ProdiNotification from "pages/Prodi/Notification";
import ProdiReport from "pages/Prodi/Report";
import ProdiProfile from "pages/Prodi/Profile";

// admin
import AdminDashboard from "pages/Admin/Dashboard";
import AdminSchedule from "pages/Admin/Schedule";
import AdminNotification from "pages/Admin/Notification";
import AdminProfile from "pages/Admin/Profile";
import AdminUser from "pages/Admin/User";
import AdminProdi from "pages/Admin/Prodi";

const App = () => {
  const location = useLocation();

  const transitionPage = () => {
    return !location.pathname.includes("mahasiswa") &&
      !location.pathname.includes("dosen") &&
      !location.pathname.includes("sba") &&
      !location.pathname.includes("prodi") &&
      !location.pathname.includes("admin")
      ? location.pathname
      : "";
  };

  return (
    <>
      <ScrollToTop />
      <PageTransition
        preset="fadeLeftFadeRight"
        transitionKey={transitionPage()}
      >
        <Routes location={location}>
          <Route path="/" element={<Default />}>
            <Route path="/" element={<Boarding />} />
            <Route path="/calendar" element={<Calendar />} />
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="login-other" element={<LoginOther />} />
            <Route path="activation" element={<Activation />} />
          </Route>

          {/* Mahasiswa */}
          <Route
            path="/mahasiswa"
            element={
              <AuthorizeUser>
                <Admin />
              </AuthorizeUser>
            }
          >
            <Route path="" element={<Navigate to="dashboard" replace />} />
            <Route path="dashboard" element={<MahasiswaDashboard />} />
            <Route path="schedule" element={<MahasiswaSchedule />} />
            <Route
              path="schedule/detail"
              element={<MahasiswaScheduleDetail />}
            />
            <Route path="notification" element={<MahasiswaNotification />} />
            <Route path="profile" element={<MahasiswaProfile />} />
          </Route>

          {/* Dosen  */}
          <Route
            path="/dosen"
            element={
              <AuthorizeUser>
                <Admin />
              </AuthorizeUser>
            }
          >
            <Route path="" element={<Navigate to="dashboard" replace />} />
            <Route path="dashboard" element={<DosenDashboard />} />
            <Route path="schedule/detail" element={<DosenSchedule />} />
            <Route path="notification" element={<DosenNotification />} />
            <Route path="profile" element={<DosenProfile />} />
          </Route>

          {/* SBA */}
          <Route
            path="/sba"
            element={
              <AuthorizeUser>
                <Admin />
              </AuthorizeUser>
            }
          >
            <Route path="" element={<Navigate to="dashboard" replace />} />
            <Route path="dashboard" element={<SBADashboard />} />
            <Route path="schedule/detail" element={<SBASchedule />} />
            <Route path="notification" element={<SBANotification />} />
            <Route path="profile" element={<SBAProfile />} />
          </Route>

          {/* Prodi */}
          <Route
            path="/prodi"
            element={
              <AuthorizeUser>
                <Admin />
              </AuthorizeUser>
            }
          >
            <Route path="" element={<Navigate to="dashboard" replace />} />
            <Route path="dashboard" element={<ProdiDashboard />} />
            <Route path="schedule/detail" element={<ProdiSchedule />} />
            <Route path="notification" element={<ProdiNotification />} />
            <Route path="report" element={<ProdiReport />} />
            <Route path="profile" element={<ProdiProfile />} />
          </Route>

          {/* Admin */}
          <Route
            path="/admin"
            element={
              <AuthorizeUser>
                <Admin />
              </AuthorizeUser>
            }
          >
            <Route path="" element={<Navigate to="dashboard" replace />} />
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="schedule/detail" element={<AdminSchedule />} />
            <Route path="notification" element={<AdminNotification />} />
            <Route path="profile" element={<AdminProfile />} />
            <Route path="user" element={<AdminUser />} />
            <Route path="prodi" element={<AdminProdi />} />
          </Route>

          {/* Not Found */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </PageTransition>
    </>
  );
};

export default App;
