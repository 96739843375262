import { useEffect, useState } from "react";
import propTypes from "prop-types";

// services
import {
  getProdis,
  renderDescriptionReservation,
  titleReservation,
} from "services/general";
import { getLecturesByProdi } from "services/Reservations";

// components
import Header from "components/Header";
import { Fade } from "react-awesome-reveal";
import Button from "components/Button";
import InputDate from "components/Form/InputDate";
import InputSelect from "components/Form/InputSelect";

// assets
import ic_microscope from "assets/icons/ic_microscope.svg";

const Guidance = (props) => {
  let { form } = props;

  const [guidanceStartDate, setGuidanceStartDate] = useState(new Date());
  const [guidanceEndDate, setGuidanceEndDate] = useState(new Date());
  const [guidanceStudyProgram, setGuidanceStudyProgram] = useState("");
  const [guidanceLecturer, setGuidanceLecturer] = useState("");

  const [fieldsProdi, setFieldsProdi] = useState([
    {
      name: "Pilih Prodi",
      value: "",
    },
  ]);

  const [fieldsDosen, setFieldsDosen] = useState([
    {
      name: "Pilih Dosen",
      value: "",
    },
  ]);

  const [lectures, setLectures] = useState([]);
  const [lecture, setLecture] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (guidanceStudyProgram) getDosen();
  }, [guidanceStudyProgram]);

  useEffect(() => {
    if (guidanceLecturer) {
      const lecture = lectures.find(
        (lecture) => lecture.id === guidanceLecturer
      );
      setLecture(lecture);
    }
  }, [guidanceLecturer]);

  useEffect(() => {
    setGuidanceStartDate(form.guidanceStartDate);
    setGuidanceEndDate(form.guidanceEndDate);
    setGuidanceStudyProgram(form.guidanceStudyProgram);
    setGuidanceLecturer(form.guidanceLecturer);
  }, [form]);

  const submit = (e) => {
    e.preventDefault();

    form = {
      ...form,
      guidanceStartDate,
      guidanceEndDate,
      guidanceStudyProgram,
      guidanceLecturer,
    };

    props.updateForm(form);
  };

  const getData = async () => {
    try {
      const response = await getProdis();
      const prodis = response.data.body.data.prodis;
      const newFields = [{ name: "Pilih Prodi", value: "" }];
      newFields.push(
        ...prodis.map((prodi) => ({
          name: prodi.name,
          value: prodi.id,
        }))
      );

      setFieldsProdi(newFields);
    } catch (error) {
      console.error(error);
    }
  };

  const getDosen = async () => {
    try {
      const response = await getLecturesByProdi({
        prodi_id: guidanceStudyProgram,
      });
      const lectures = response.data.body.data.lectures;
      const newFields = [{ name: "Pilih Dosen", value: "" }];
      newFields.push(
        ...lectures.map((lecture) => ({
          name: lecture.name,
          value: lecture.id,
        }))
      );

      setLectures(lectures);
      setFieldsDosen(newFields);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Fade duration={750}>
      <form onSubmit={submit}>
        <Header
          icon={<img src={ic_microscope} alt="Icon Microscope" />}
          title="Informasi Bimbingan"
        />

        <div className="mt-6 grid gap-5 md:grid-cols-2">
          <div>
            <h5 className="mb-3 text-lg font-medium">
              Tanggal Mulai Bimbingan
            </h5>

            <InputDate
              name="guidanceStartDate"
              value={guidanceStartDate}
              placeholder="Tanggal Mulai Bimbingan"
              onChange={(value) => setGuidanceStartDate(value)}
            />
          </div>

          <div>
            <h5 className="mb-3 text-lg font-medium">
              Tanggal Akhir Bimbingan
            </h5>

            <InputDate
              name="guidanceEndDate"
              value={guidanceEndDate}
              placeholder="Tanggal Akhir Bimbingan"
              onChange={(value) => setGuidanceEndDate(value)}
            />
          </div>

          <div>
            <h5 className="mb-3 text-lg font-medium">Prodi Dosen Pembimbing</h5>

            <InputSelect
              name="guidanceStudyProgram"
              value={guidanceStudyProgram}
              fields={fieldsProdi}
              onChange={(e) => setGuidanceStudyProgram(e.target.value)}
            />
          </div>

          <div>
            <h5 className="mb-3 text-lg font-medium">Dosen Pembimbing</h5>

            <InputSelect
              name="guidanceLecturer"
              value={guidanceLecturer}
              fields={fieldsDosen}
              onChange={(e) => setGuidanceLecturer(e.target.value)}
            />
          </div>
        </div>

        {lecture && (
          <div className="mt-5 grid gap-5 md:grid-cols-2">
            <div>
              <h5 className="mb-3 text-lg font-medium">
                Profil Dosen Pembimbing
              </h5>
              <div className="rounded-2xl border-2 border-accent-black bg-[#1010100D] p-4">
                <div className="flex items-center gap-3">
                  <img
                    src={`${process.env.REACT_APP_STORAGE}avatars/${lecture.avatar}`}
                    alt="Avatar"
                    className="h-[70px] w-[70px] rounded-full border-2 border-accent-black object-cover p-1"
                  />

                  <div>
                    <h5 className="text-base font-semibold">{lecture?.name}</h5>
                    <span className="font-medium capitalize text-primary-yellow">
                      {lecture.prodi.name}
                    </span>
                    <span className="block font-medium capitalize">
                      {lecture.email} | {lecture.phone}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h5 className="mb-3 text-lg font-medium">
                Jadwal Sidang Terbaru Dosen
              </h5>

              {lecture.guidances_reservations.map((reservation, index) => (
                <div className="card mb-4 flex flex-col !items-start rounded-2xl">
                  <h5 className="mb-1 text-xl font-medium">
                    {titleReservation(reservation)}
                  </h5>
                  {renderDescriptionReservation(reservation)}
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="my-10 flex items-center justify-between">
          <Button
            isSecondary
            className="border-2 border-accent-black !px-10 !text-accent-black"
            onClick={props.onBack}
          >
            Sebelumnya
          </Button>
          <Button isPrimary type="submit" className="!px-10">
            Berikutnya
          </Button>
        </div>
      </form>
    </Fade>
  );
};

export default Guidance;

Guidance.propTypes = {
  form: propTypes.object.isRequired,
  updateForm: propTypes.func.isRequired,
  onBack: propTypes.func.isRequired,
};
