import { useState, useRef, useEffect, useCallback } from "react";
import propTypes from "prop-types";
import toast from "react-hot-toast";

// services
import { formatNumber } from "services/general";
import Button from "components/Button";

const InputFile = (props) => {
  const { value, accept, max, maxValidation } = props;

  const [src, setSrc] = useState("");
  const [title, setTitle] = useState("Tambah File");
  const [desc, setDesc] = useState(
    <>
      atau seret dan lepas di sini.
      <i className="text-en">or drag and drop here.</i>
    </>
  );

  const fileWrap = useRef(null);
  const input = useRef(null);

  const handleInput = (e) => {
    e.preventDefault();
    showFile(e.target.files[0]);
  };

  const handleDrop = (e) => {
    e.preventDefault();

    if (e.dataTransfer.files.length) {
      showFile(e.dataTransfer.files[0]);
    } else {
      toast.error(<span>Yang anda unggah bukan file!</span>);
    }
  };

  const showFile = useCallback(
    (file) => {
      const fileType = file.type;
      const fileSize = Math.round(file.size / 1024);
      const validExtensions = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
      ];

      // Check extension
      if (validExtensions.includes(fileType)) {
        // Check size
        if (fileSize > max) {
          toast.error(<span>File melebihi batas size {maxValidation}!</span>);
          return;
        }

        const fileReader = new FileReader();

        // File on load
        fileReader.onload = () => {
          setTitle(file.name);
          setSrc(fileReader.result);

          if (fileSize >= 1000) {
            setDesc(`Size - ${formatNumber(fileSize)} MB`);
          } else {
            setDesc(`Size - ${fileSize} KB`);
          }

          // Send Event
          props.onChange(file);
        };

        fileReader.readAsDataURL(file);
      } else {
        toast.error(<span>Yang anda unggah bukan file!</span>);
      }
    },
    [props]
  );

  useEffect(() => {
    if (value) {
      showFile(value);
    }
  }, [value]);

  return (
    <>
      <div
        className={["card card-form !justify-between !py-0 !px-5"].join(" ")}
      >
        <span>{title}</span>
        <input
          type="file"
          value=""
          ref={input}
          accept={accept}
          hidden
          onChange={handleInput}
        />
        <Button
          className="my-3 !rounded-lg !py-1"
          isSecondary
          onClick={() => input.current.click()}
        >
          Pilih File
        </Button>
      </div>

      <span className="mt-3 block text-sm text-red">
        Maksimal File {maxValidation}.
      </span>
    </>
  );
};

export default InputFile;

InputFile.defaultProps = {
  accept: "image/png, image/jpeg, image/jpg",
  max: 5120,
  maxValidation: "5 MB",
};

InputFile.propTypes = {
  name: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
  value: propTypes.object,
  accept: propTypes.string,
  max: propTypes.number,
  maxValidation: propTypes.string,
};
