import { useState } from "react";
import { useNavigate } from "react-router";

// components
import TFade from "components/Transition/Fade";
import ArrowBack from "components/ArrowBack";
import SearchRoom from "./SearchRoom";
import Identity from "./Identity";
import Guidance from "./Guidance";
import Thesis from "./Thesis";
import RequirementsFile from "./RequirementsFile";
import CheckData from "./CheckData";

// assets
import ic_document from "assets/icons/ic_document.svg";
import bgCircle from "assets/images/bg-circle.svg";

const Schedule = () => {
  const steps = [1, 2, 3, 4];
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [form, setForm] = useState({
    date: new Date(),
    session: 0,
    name: "",
    npm: "",
    height: "",
    address: "",
    phoneNumber: "",
    guidanceStartDate: new Date(),
    guidanceEndDate: new Date(),
    guidanceStudyProgram: "",
    guidanceLecturer: "",
    titleId: "",
    titleEn: "",
    suratTugasJadwalBimbingan: null,
    validasiSimilarity: null,
    transkripAkademik: null,
    pembayaranUangKuliah: null,
    krsTerakhir: null,
    draftSkripsi: null,
    filePresentasi: null,
    selesaiRevisi: null,
    buktiMenghadiri: null,
    foto4x6: null,
    foto3x4: null,
    foto2x3: null,
    ktm: null,
    toefl: null,
    bebasPinjamBukuUniversitas: null,
    bebasPinjamBukuFakultas: null,
  });

  const updateForm = (value) => {
    setForm(value);

    if (currentStep < 6) setCurrentStep((state) => state + 1);
    else navigate("/mahasiswa");
  };

  return (
    <TFade>
      <ArrowBack />

      <div className="relative z-0 mt-10 mb-6 flex flex-col justify-center overflow-hidden rounded-2xl bg-cream px-8 py-10">
        <img
          src={bgCircle}
          alt="Background Circle"
          className="absolute right-0 bottom-0 -z-0"
        />

        <h2 className="text-3xl font-semibold">
          Buat Reservasi Ruangan Sidang
        </h2>

        <p>
          Lengkapi persyaratan di bawah ini untuk membuat reservasi ruangan
          sidang, pastikan <br /> setelah mengisi semua persyaratan diperiksa
          kembali data dan file yang dimasukan.
        </p>
      </div>

      <ul className="step-wizard-list mb-6">
        {steps.map((step, index) => (
          <li
            key={`step-${index}`}
            className={`step-wizard-item ${
              currentStep > index + 1 ? "success" : ""
            }`}
          >
            <div>
              <span className="progress-count">{step}</span>
            </div>
          </li>
        ))}
        <li className="step-wizard-item">
          <div>
            <span className="progress-count">
              <img src={ic_document} alt="Icon Document" />
            </span>
          </div>
        </li>
      </ul>

      <div>
        {currentStep === 1 ? (
          <SearchRoom form={form} updateForm={(value) => updateForm(value)} />
        ) : currentStep === 2 ? (
          <Identity
            form={form}
            updateForm={(value) => updateForm(value)}
            onBack={() => setCurrentStep((state) => state - 1)}
          />
        ) : currentStep === 3 ? (
          <Guidance
            form={form}
            updateForm={(value) => updateForm(value)}
            onBack={() => setCurrentStep((state) => state - 1)}
          />
        ) : currentStep === 4 ? (
          <Thesis
            form={form}
            updateForm={(value) => updateForm(value)}
            onBack={() => setCurrentStep((state) => state - 1)}
          />
        ) : currentStep === 5 ? (
          <RequirementsFile
            form={form}
            updateForm={(value) => updateForm(value)}
            onBack={() => setCurrentStep((state) => state - 1)}
          />
        ) : (
          <CheckData
            form={form}
            updateForm={(value) => updateForm(value)}
            onBack={() => setCurrentStep((state) => state - 1)}
          />
        )}
      </div>
    </TFade>
  );
};

export default Schedule;
