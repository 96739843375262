export default function authHeaders(isFile = false) {
  if (isFile) {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
        token: localStorage.getItem("token") ?? "",
      },
    };
  }

  return {
    headers: {
      token: localStorage.getItem("token") ?? "",
    },
  };
}
