import { Fade } from "react-awesome-reveal";
import propTypes from "prop-types";
import toast from "react-hot-toast";

// services
import { confirmModal, successModal } from "services/general";
import { createReservation } from "services/Reservations";

// components
import Header from "components/Header";
import Button from "components/Button";

// assets
import ic_sync from "assets/icons/ic_sync.svg";
import ic_check from "assets/icons/ic_check.svg";
import ic_microscope from "assets/icons/ic_microscope.svg";
import ic_suitcase from "assets/icons/ic_suitcase.svg";
import ic_calendar from "assets/icons/ic_calendar_small.svg";
import ic_document from "assets/icons/ic_document.svg";
import ic_male from "assets/icons/ic_male.svg";

const CheckData = (props) => {
  let { form } = props;

  const submit = (e) => {
    e.preventDefault();

    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await createReservation(form);
          successModal("Success", "Data berhasil disimpan!");
          props.updateForm(form);
        } catch (error) {
          const response = error.response.data.body;

          if (response.validations) {
            response.validations.errors.forEach((message) =>
              toast.error(message.msg)
            );
          } else {
            toast.error(response.message);
          }

          console.error(error);
        }
      }
    });
  };

  return (
    <Fade duration={750}>
      <form onSubmit={submit}>
        <Header
          icon={<img src={ic_sync} alt="Icon Sync" />}
          title="Cek Kelengkapan Data"
        />

        <div className="mt-8 grid gap-x-8 gap-y-5 md:grid-cols-2">
          <div className="flex items-center justify-between">
            <Header
              icon={<img src={ic_microscope} alt="Icon Microscope" />}
              title="Informasi Bimbingan"
            />

            <div className="flex h-8 w-8 items-center justify-center rounded-full border-2 border-accent-black bg-green p-1">
              <img src={ic_check} alt="Icon Check" />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <Header
              icon={<img src={ic_suitcase} alt="Icon Suitcase" />}
              title="Informasi skripsi"
            />

            <div className="flex h-8 w-8 items-center justify-center rounded-full border-2 border-accent-black bg-green p-1">
              <img src={ic_check} alt="Icon Check" />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <Header
              icon={<img src={ic_calendar} alt="Icon Calendar" />}
              title="Tanggal Reservasi Ruangan"
            />

            <div className="flex h-8 w-8 items-center justify-center rounded-full border-2 border-accent-black bg-green p-1">
              <img src={ic_check} alt="Icon Check" />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <Header
              icon={<img src={ic_document} alt="Icon Document" />}
              title="Berkas Persyaratan"
            />

            <div className="flex h-8 w-8 items-center justify-center rounded-full border-2 border-accent-black bg-green p-1">
              <img src={ic_check} alt="Icon Check" />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <Header
              icon={<img src={ic_male} alt="Icon Male" />}
              title="Identitas Diri"
            />

            <div className="flex h-8 w-8 items-center justify-center rounded-full border-2 border-accent-black bg-green p-1">
              <img src={ic_check} alt="Icon Check" />
            </div>
          </div>
        </div>

        <div className="my-10 flex items-center justify-between">
          <Button
            isSecondary
            className="border-2 border-accent-black !px-10 !text-accent-black"
            onClick={props.onBack}
          >
            Sebelumnya
          </Button>
          <Button isPrimary type="submit" className="!px-10">
            Berikutnya
          </Button>
        </div>
      </form>
    </Fade>
  );
};

export default CheckData;

CheckData.propTypes = {
  form: propTypes.object.isRequired,
  updateForm: propTypes.func.isRequired,
  onBack: propTypes.func.isRequired,
};
