import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// services
import { getReservationsStudent } from "services/Reservations";

// components
import TFade from "components/Transition/Fade";
import Header from "components/Header";
import ReservationsCalendar from "components/ReservationsCalendar";
import ReservationItem from "components/ReservationItem";
import NotFound from "components/NotFound";

// assets
import ic_calendar from "assets/icons/ic_calendar.svg";
import ic_full_screen from "assets/icons/ic_full_screen.svg";
import ic_time from "assets/icons/ic_time.svg";

const Dashboard = () => {
  const [reservationsNow, setReservationsNow] = useState(null);
  const [reservationsRecent, setReservationsRecent] = useState([]);

  const openFullScreen = () => {
    const elem = document.documentElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await getReservationsStudent();
      setReservationsNow(response.data.body.data.reservation_now);
      setReservationsRecent(response.data.body.data.reservation_recent);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <TFade>
      <div className="mb-5 flex flex-wrap items-center justify-between gap-3">
        <Header
          icon={<img src={ic_calendar} alt="Icon Calendar" />}
          title="Kalendar Reservasi Ruangan"
        />

        <div
          className="flex cursor-pointer items-center gap-3"
          onClick={openFullScreen}
        >
          <img src={ic_full_screen} alt="Icon Full Screen" />
          <span className="text-lg font-medium">Full Screen</span>
        </div>
      </div>

      {/* Calendar */}
      <ReservationsCalendar />

      <Header
        icon={<img src={ic_time} alt="Icon Time" />}
        title="Reservasi Ruangan terbaru"
        className="mt-5"
      />

      {reservationsNow ? (
        <ReservationItem data={reservationsNow} />
      ) : (
        <Link
          to="/mahasiswa/schedule"
          className="my-5 flex h-24 cursor-pointer flex-col items-center justify-center rounded-2xl border-[3px] border-dashed border-accent-black bg-gray text-center transition duration-300"
        >
          <div className="flex items-center gap-4">
            <img src={ic_calendar} alt="Icon Calendar" />
            <h5 className="text-xl font-medium">Buat Reservasi Ruang Sidang</h5>
          </div>
        </Link>
      )}

      <Header
        icon={<img src={ic_time} alt="Icon Time" />}
        title="Riwayat Reservasi Ruangan"
      />

      {reservationsRecent.length > 0 ? (
        reservationsRecent.map((reservation, index) => (
          <ReservationItem data={reservation} key={`reservation-${index}`} />
        ))
      ) : (
        <NotFound />
      )}
    </TFade>
  );
};

export default Dashboard;
