import { useEffect, useState } from "react";
import propTypes from "prop-types";

// components
import Header from "components/Header";
import { Fade } from "react-awesome-reveal";
import InputText from "components/Form/InputText";
import InputTextArea from "components/Form/InputTextArea";
import InputFormat from "components/Form/InputFormat";
import Button from "components/Button";

// assets
import ic_male from "assets/icons/ic_male.svg";

const Identity = (props) => {
  let { form } = props;

  const [name, setName] = useState("");
  const [npm, setNpm] = useState("");
  const [height, setHeight] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    setName(form.name);
    setNpm(form.npm);
    setHeight(form.height);
    setAddress(form.address);
    setPhoneNumber(form.phoneNumber);
  }, [form]);

  const submit = (e) => {
    e.preventDefault();

    form = {
      ...form,
      name,
      npm,
      height,
      address,
      phoneNumber,
    };

    props.updateForm(form);
  };

  return (
    <Fade duration={750}>
      <form onSubmit={submit}>
        <Header
          icon={<img src={ic_male} alt="Icon Male" />}
          title="Identitas Diri"
        />

        <h5 className="mt-6 mb-3 text-lg font-medium">
          Nama Lengkap(sesuai Akta Kelahiran)
        </h5>

        <InputText
          name="name"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <h5 className="mt-6 mb-3 text-lg font-medium">NPM</h5>

        <InputFormat
          name="npm"
          type="number"
          placeholder="NPM"
          template="xxxx - xx - xx - xx - xx"
          value={npm}
          onChange={(value) => setNpm(value)}
          onDelete={() => setNpm("")}
        />

        <h5 className="mt-6 mb-3 text-lg font-medium">Alamat</h5>

        <InputTextArea
          name="address"
          placeholder="Address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />

        <h5 className="mt-6 mb-3 text-lg font-medium">
          No. Tlp yang Dapat Dihubungi
        </h5>

        <InputText
          name="phoneNumber"
          type="number"
          placeholder="Nomor Telepon"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />

        <div className="my-10 flex items-center justify-between">
          <Button
            isSecondary
            className="border-2 border-accent-black !px-10 !text-accent-black"
            onClick={props.onBack}
          >
            Sebelumnya
          </Button>
          <Button isPrimary type="submit" className="!px-10">
            Berikutnya
          </Button>
        </div>
      </form>
    </Fade>
  );
};

export default Identity;

Identity.propTypes = {
  form: propTypes.object.isRequired,
  updateForm: propTypes.func.isRequired,
  onBack: propTypes.func.isRequired,
};
