import { useEffect, useState } from "react";
import propTypes from "prop-types";
import { Fade } from "react-awesome-reveal";
import Calendar from "react-calendar";
import toast from "react-hot-toast";
import moment from "moment";

// services
import { checkSchedule } from "services/Reservations";

// components
import Header from "components/Header";
import InputSelect from "components/Form/InputSelect";
import Button from "components/Button";

// assets
import ic_calendar from "assets/icons/ic_calendar.svg";
import ic_mic from "assets/icons/ic_mic.svg";

import "react-calendar/dist/Calendar.css";

const SearchRoom = (props) => {
  let { form } = props;

  const [date, setDate] = useState(new Date());
  const [session, setSession] = useState("");
  const [hasRoom, setHasRoom] = useState(true);

  useEffect(() => {
    setDate(form.date);
    setSession(form.session);
  }, [form]);

  const fields = [
    {
      name: "Waiting List",
      value: 0,
    },
  ];

  const submit = (e) => {
    e.preventDefault();

    form = {
      ...form,
      date,
      session,
    };

    props.updateForm(form);
  };

  const checkRoom = async () => {
    try {
      const data = await checkSchedule({
        date: moment(form.date).format("YYYY-MM-DD"),
        session,
      });

      if (data.data.body.status == true) {
        setHasRoom(true);
        toast.success(
          "Ruangan tersedia, kamu akan dimasukan kedalam waiting list."
        );
      } else {
        setHasRoom(false);
        toast.error("Ruangan tidak tersedia, pilih tanggal atau sesi lainnya.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fade duration={750}>
      <form onSubmit={submit}>
        <Header
          icon={<img src={ic_calendar} alt="Icon Calendar" />}
          title="Tanggal Reservasi Ruangan"
        />

        <div className="card my-4 rounded-2xl">
          <Calendar
            onChange={(value) => setDate(value)}
            value={date}
            className="!w-full border-none"
          />
        </div>

        <h5 className="my-4 text-lg font-medium">Sesi Sidang</h5>

        <InputSelect
          name="session"
          value={session}
          fields={fields}
          onChange={(e) => setSession(e.target.value)}
          disabled
        />

        <div className="mt-5 flex flex-wrap items-center justify-between gap-3">
          <Button isPrimary type="submit" className="!px-10">
            Berikutnya
          </Button>
        </div>
      </form>
    </Fade>
  );
};

export default SearchRoom;

SearchRoom.propTypes = {
  form: propTypes.object.isRequired,
  updateForm: propTypes.func.isRequired,
};
