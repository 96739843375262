import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

// services
import { updateProfile } from "services/User";
import { confirmModal, getProdis, successModal } from "services/general";

// features
import { VerifyUser } from "features/authSlice";

// components
import TFade from "components/Transition/Fade";
import InputText from "components/Form/InputText";
import InputFormat from "components/Form/InputFormat";
import InputSelect from "components/Form/InputSelect";
import Button from "components/Button";

// assets
import ic_chevron_down from "assets/icons/ic_chevron_down.svg";
import ic_camera from "assets/icons/ic_camera.svg";

const Profile = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [prodi, setProdi] = useState("");
  const [fields, setFields] = useState([
    {
      name: "Pilih Prodi",
      value: "",
    },
  ]);

  const input = useRef(null);
  const [src, setSrc] = useState("");

  const handleInput = (e) => {
    e.preventDefault();
    showFile(e.target.files[0]);
  };

  const showFile = useCallback((file) => {
    const fileType = file.type;
    const fileSize = Math.round(file.size / 1024);
    const validExtensions = ["image/jpeg", "image/jpg", "image/png"];

    // Check extension
    if (validExtensions.includes(fileType)) {
      // Check size
      if (fileSize > 5120) {
        toast.error(<span>File melebihi batas size 5 MB!</span>);
        return;
      }

      const fileReader = new FileReader();

      // File on load
      fileReader.onload = () => {
        setSrc(fileReader.result);

        // Send Event
        setAvatar(file);
      };

      fileReader.readAsDataURL(file);
    } else {
      toast.error(<span>Yang anda unggah bukan file!</span>);
    }
  }, []);

  useEffect(() => {
    if (avatar) {
      showFile(avatar);
    }
  }, [avatar]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (user) {
      setName(user.name);
      setPhoneNumber(user.phone);
      setProdi(user.prodi_id);
    }
  }, [user]);

  const getData = async () => {
    try {
      const response = await getProdis();
      const prodis = response.data.body.data.prodis;
      const newFields = [{ name: "Pilih Prodi", value: "" }];
      newFields.push(
        ...prodis.map((prodi) => ({
          name: prodi.name,
          value: prodi.id,
        }))
      );

      setFields(newFields);
    } catch (error) {
      console.error(error);
    }
  };

  const submit = (e) => {
    e.preventDefault();

    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await updateProfile({
            name: name,
            phone: phoneNumber,
            prodi_id: prodi,
            avatar: avatar,
          });

          getUser();
          successModal("Success", "Data berhasil disimpan!");
        } catch (error) {
          toast.error(error.response.data.body.message);
          console.error(error);
        }
      }
    });
  };

  const getUser = () => {
    dispatch(VerifyUser());
  };

  return (
    <TFade>
      {/* Background */}
      <div className="relative h-[225px] rounded-[15px] border-2 bg-cream"></div>

      {user && (
        <>
          {/* Avatar */}
          <div className="group relative z-[1] mx-auto -mt-16 h-[100px] w-[100px] overflow-hidden rounded-full border-2 bg-white object-cover p-2 md:h-[120px] md:w-[120px]">
            <img
              src={
                src || `${process.env.REACT_APP_STORAGE}avatars/${user?.avatar}`
              }
              alt="Avatar"
              className="h-full w-full select-none rounded-full object-cover"
            />

            <div
              onClick={() => input.current.click()}
              className="absolute top-0 left-0 flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-[#10101049] opacity-0 transition group-hover:opacity-100"
            >
              <img src={ic_camera} alt="Icon Camera" className="select-none" />
            </div>

            <input
              type="file"
              value=""
              ref={input}
              accept="image/png, image/jpeg, image/jpg"
              hidden
              onChange={handleInput}
            />
          </div>

          {/* Form */}
          <form onSubmit={submit} className="mt-5">
            <div className="mt-6 mb-8 grid grid-cols-2 gap-4">
              <InputText
                name="name"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onDelete={() => setName("")}
              />

              <InputText
                name="phoneNumber"
                type="number"
                placeholder="Nomor Telepon"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                onDelete={() => setPhoneNumber("")}
              />

              <InputText
                name="email"
                type="email"
                placeholder="Email"
                value={user.email}
                disabled
              />

              {user.role === "mahasiswa" ? (
                <InputFormat
                  name="npm"
                  type="number"
                  placeholder="NPM"
                  template="xxxx - xx - xx - xx - xx"
                  value={user.npm}
                  disabled
                />
              ) : (
                <InputFormat
                  name="nip"
                  type="number"
                  placeholder="NIP"
                  template="xxxx-xx-xx-xxxx-xx-x-xxx"
                  value={user.nip}
                  disabled
                />
              )}

              <InputSelect
                name="prodi"
                value={prodi}
                fields={fields}
                append={<img src={ic_chevron_down} alt="Icon Chevron Down" />}
                onChange={(e) => setProdi(e.target.value)}
              />
            </div>

            <div className="flex justify-end">
              <Button type="submit" isPrimary>
                Update Profile
              </Button>
            </div>
          </form>
        </>
      )}
    </TFade>
  );
};

export default Profile;
