import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";

// features
import { RegisterUser, resetMsg, resetSuccess } from "features/authSlice";

// services
import { getProdis } from "services/general";

// components
import ArrowBack from "components/ArrowBack";
import Logo from "components/Logo";
import InputText from "components/Form/InputText";
import InputPassword from "components/Form/InputPassword";
import Button from "components/Button";
import Copyright from "components/Copyright";
import InputSelect from "components/Form/InputSelect";
import InputFile from "components/Form/InputFile";
import InputFormat from "components/Form/InputFormat";

// assets
import ic_chevron_down from "assets/icons/ic_chevron_down.svg";

const Register = () => {
  const [name, setName] = useState("");
  const [npm, setNpm] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [prodi, setProdi] = useState("");
  const [fields, setFields] = useState([
    {
      name: "Pilih Prodi",
      value: "",
    },
  ]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSuccess, isLoading, message, listMessage } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (message) toast.error(message);
    if (listMessage) {
      listMessage.forEach((message) => toast.error(message.msg));
    }
    dispatch(resetMsg());
  }, [message, listMessage, dispatch]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(resetSuccess());
      toast.success("Register success");
      navigate("/login");
    }
  }, [isSuccess, navigate]);

  const getData = async () => {
    try {
      const response = await getProdis();
      const prodis = response.data.body.data.prodis;
      const newFields = [{ name: "Pilih Prodi", value: "" }];
      newFields.push(
        ...prodis.map((prodi) => ({
          name: prodi.name,
          value: prodi.id,
        }))
      );

      setFields(newFields);
    } catch (error) {
      console.error(error);
    }
  };

  const Auth = (e) => {
    e.preventDefault();

    dispatch(
      RegisterUser({
        name: name,
        email: email,
        phone: phoneNumber,
        password: password,
        npm: npm,
        prodi_id: prodi,
        avatar: avatar,
      })
    );
  };

  return (
    <>
      <div className="bg-grid flex flex-col justify-between ">
        <div className="mt-10 flex w-full items-center justify-between">
          <ArrowBack />

          <div>
            <Logo />
          </div>
        </div>

        {/* Form */}
        <form onSubmit={Auth}>
          <h1 className="text-xl font-semibold lg:text-4xl">
            Daftar Akun Mahasiswa
          </h1>
          <h4 className="text-accent-gray text-base lg:text-xl">
            Buat akun mahasiswa kamu dulu sebelum reservasi ruangan <br /> untuk
            sidang kamu.
          </h4>

          <div className="mt-6 grid grid-cols-2 gap-4">
            <InputText
              name="name"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              onDelete={() => setName("")}
            />

            <InputFormat
              name="npm"
              type="number"
              placeholder="NPM"
              template="xxxx - xx - xx - xx - xx"
              value={npm}
              onChange={(value) => setNpm(value)}
              onDelete={() => setNpm("")}
            />

            <InputText
              name="email"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onDelete={() => setEmail("")}
            />

            <InputPassword
              name="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <InputText
              name="phoneNumber"
              type="number"
              placeholder="Nomor Telepon"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              onDelete={() => setPhoneNumber("")}
            />

            <InputFile
              name="avatar"
              value={avatar}
              onChange={(file) => setAvatar(file)}
            />
          </div>

          <InputSelect
            name="prodi"
            inputClassName="mt-4"
            value={prodi}
            fields={fields}
            append={<img src={ic_chevron_down} alt="Icon Chevron Down" />}
            onChange={(e) => setProdi(e.target.value)}
          />

          <div className="mt-6 flex justify-center">
            <Button
              isPrimary
              // isDisabled={email === "" || password === ""}
              className="w-1/3"
              type="submit"
              isLoading={isLoading}
            >
              Masuk Akun
            </Button>
          </div>
        </form>

        {/* Copyright */}
        <Copyright />
      </div>
    </>
  );
};

export default Register;
