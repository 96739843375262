import { useEffect, useState } from "react";
import propTypes from "prop-types";
import { Fade } from "react-awesome-reveal";
import toast from "react-hot-toast";

// components
import Header from "components/Header";
import Button from "components/Button";
import InputFile from "components/Form/InputFile";

// assets
import ic_folder from "assets/icons/ic_folder.svg";

const RequirementsFile = (props) => {
  let { form } = props;

  const [suratTugasJadwalBimbingan, setSuratTugasJadwalBimbingan] =
    useState(null);
  const [validasiSimilarity, setValidasiSimilarity] = useState(null);
  const [transkripAkademik, setTranskripAkademik] = useState(null);
  const [pembayaranUangKuliah, setPembayaranUangKuliah] = useState(null);
  const [krsTerakhir, setKrsTerakhir] = useState(null);
  const [draftSkripsi, setDraftSkripsi] = useState(null);
  const [filePresentasi, setFilePresentasi] = useState(null);
  const [selesaiRevisi, setSelesaiRevisi] = useState(null);
  const [buktiMenghadiri, setBuktiMenghadiri] = useState(null);
  const [foto4x6, setFoto4x6] = useState(null);
  const [foto3x4, setFoto3x4] = useState(null);
  const [foto2x3, setFoto2x3] = useState(null);
  const [ktm, setKtm] = useState(null);
  const [toefl, setToefl] = useState(null);
  const [bebasPinjamBukuUniversitas, setBebasPinjamBukuUniversitas] =
    useState(null);
  const [bebasPinjamBukuFakultas, setBebasPinjamBukuFakultas] = useState(null);

  useEffect(() => {
    setSuratTugasJadwalBimbingan(form.suratTugasJadwalBimbingan);
    setValidasiSimilarity(form.validasiSimilarity);
    setTranskripAkademik(form.transkripAkademik);
    setPembayaranUangKuliah(form.pembayaranUangKuliah);
    setKrsTerakhir(form.krsTerakhir);
    setDraftSkripsi(form.draftSkripsi);
    setFilePresentasi(form.filePresentasi);
    setSelesaiRevisi(form.selesaiRevisi);
    setBuktiMenghadiri(form.buktiMenghadiri);
    setFoto4x6(form.foto4x6);
    setFoto3x4(form.foto3x4);
    setFoto2x3(form.foto2x3);
    setKtm(form.ktm);
    setToefl(form.toefl);
    setBebasPinjamBukuUniversitas(form.bebasPinjamBukuUniversitas);
    setBebasPinjamBukuFakultas(form.bebasPinjamBukuFakultas);
  }, [form]);

  const submit = (e) => {
    e.preventDefault();

    if (!suratTugasJadwalBimbingan) {
      toast.error("Surat Tugas dan Jadwal Bimbingan harus diisi!");
      return;
    } else if (!validasiSimilarity) {
      toast.error("Validasi Similarity harus diisi!");
      return;
    } else if (!transkripAkademik) {
      toast.error("Transkrip Akademik harus diisi!");
      return;
    } else if (!pembayaranUangKuliah) {
      toast.error("Pembayaran Uang Kuliah harus diisi!");
      return;
    } else if (!krsTerakhir) {
      toast.error("KRS Terakhir harus diisi!");
      return;
    } else if (!draftSkripsi) {
      toast.error("Draft Skripsi harus diisi!");
      return;
    } else if (!filePresentasi) {
      toast.error("File Presentasi harus diisi!");
      return;
    } else if (!selesaiRevisi) {
      toast.error("Selesai Revisi harus diisi!");
      return;
    } else if (!buktiMenghadiri) {
      toast.error("Bukti Menghadiri harus diisi!");
      return;
    } else if (!foto4x6) {
      toast.error("Foto 4x6 harus diisi!");
      return;
    } else if (!foto3x4) {
      toast.error("Foto 3x4 harus diisi!");
      return;
    } else if (!foto2x3) {
      toast.error("Foto 2x3 harus diisi!");
      return;
    } else if (!ktm) {
      toast.error("KTM harus diisi!");
      return;
    } else if (!toefl) {
      toast.error("Toefl harus diisi!");
      return;
    } else if (!bebasPinjamBukuUniversitas) {
      toast.error("Bebas Pinjam Buku Universitas harus diisi!");
      return;
    } else if (!bebasPinjamBukuFakultas) {
      toast.error("Bebas Pinjam Buku Fakultas harus diisi!");
      return;
    }

    form = {
      ...form,
      suratTugasJadwalBimbingan,
      validasiSimilarity,
      transkripAkademik,
      pembayaranUangKuliah,
      krsTerakhir,
      draftSkripsi,
      filePresentasi,
      selesaiRevisi,
      buktiMenghadiri,
      foto4x6,
      foto3x4,
      foto2x3,
      ktm,
      toefl,
      bebasPinjamBukuUniversitas,
      bebasPinjamBukuFakultas,
    };

    props.updateForm(form);
  };

  return (
    <Fade duration={750}>
      <form onSubmit={submit}>
        <Header
          icon={<img src={ic_folder} alt="Icon Folder" />}
          title="Berkas Persyaratan "
        />

        <h5 className="mt-6 mb-3 text-lg font-medium">
          Melampirkan Surat Tugas dan Jadwal Bimbingan Skripsi yang sudah
          disetujui Pembimbing dan ditandatangani Ketua Program Studi
        </h5>

        <InputFile
          name="suratTugasJadwalBimbingan"
          value={suratTugasJadwalBimbingan}
          onChange={(file) => setSuratTugasJadwalBimbingan(file)}
          accept="application/pdf"
          max={350}
          maxValidation="350 KB"
        />

        <h5 className="mt-6 mb-3 text-lg font-medium">
          Melampirkan lembar validasi similarity pengecekan Turnitin skripsi
          yang ditandatangan petugas perpustakaan
        </h5>

        <InputFile
          name="validasiSimilarity"
          value={validasiSimilarity}
          onChange={(file) => setValidasiSimilarity(file)}
          accept="application/pdf"
          max={350}
          maxValidation="350 KB"
        />

        <h5 className="mt-6 mb-3 text-lg font-medium">
          Transkrip Akademik yang sudah ditandatangani oleh Wakil Dekan I
        </h5>

        <InputFile
          name="transkripAkademik"
          value={transkripAkademik}
          onChange={(file) => setTranskripAkademik(file)}
          accept="application/pdf"
          max={350}
          maxValidation="350 KB"
        />

        <h5 className="mt-6 mb-3 text-lg font-medium">
          Tanda Bukti Pembayaran Uang Kuliah/Registrasi Terakhir
        </h5>

        <InputFile
          name="pembayaranUangKuliah"
          value={pembayaranUangKuliah}
          onChange={(file) => setPembayaranUangKuliah(file)}
          accept="application/pdf"
          max={350}
          maxValidation="350 KB"
        />

        <h5 className="mt-6 mb-3 text-lg font-medium">KRS Terakhir</h5>

        <InputFile
          name="krsTerakhir"
          value={krsTerakhir}
          onChange={(file) => setKrsTerakhir(file)}
          accept="application/pdf"
          max={350}
          maxValidation="350 KB"
        />

        <h5 className="mt-6 mb-3 text-lg font-medium">
          Menyerahkan Draft Skripsi yang telah ditanda tangani oleh pembimbing
          sebanyak 4 eksemplar bagi 1 Pembimbing dan 5 eksemplar bagi 2
          Pembimbing dengan melampirkan Surat Pernyataan Keaslian Karya Ilmiah
          bermaterai Rp 6000,-
        </h5>

        <InputFile
          name="draftSkripsi"
          value={draftSkripsi}
          onChange={(file) => setDraftSkripsi(file)}
          accept="application/pdf"
          max={10000}
          maxValidation="10 MB"
        />

        <h5 className="mt-6 mb-3 text-lg font-medium">
          Menyerahkan File Presentasi Sidang Skripsi
        </h5>

        <InputFile
          name="filePresentasi"
          value={filePresentasi}
          onChange={(file) => setFilePresentasi(file)}
          accept="application/pdf"
          max={10000}
          maxValidation="10 MB"
        />

        <h5 className="mt-6 mb-3 text-lg font-medium">
          Menyerahkan lembar selesai revisi dari Seminar Usulan Penelitian
          Skripsi yang ditandatangan Pembimbing
        </h5>

        <InputFile
          name="selesaiRevisi"
          value={selesaiRevisi}
          onChange={(file) => setSelesaiRevisi(file)}
          accept="application/pdf"
          max={350}
          maxValidation="350 KB"
        />

        <h5 className="mt-6 mb-3 text-lg font-medium">
          Menyerahkan sedikitnya 2 (dua) buah foto bukti menghadiri Seminar UP,
          Sidang Skripsi atau Diseminasi Paper Terpublikasi
        </h5>

        <InputFile
          name="buktiMenghadiri"
          value={buktiMenghadiri}
          onChange={(file) => setBuktiMenghadiri(file)}
          accept="application/pdf"
          max={300}
          maxValidation="300 KB"
        />

        <h5 className="mt-6 mb-3 text-lg font-medium">Pas Photo Ukuran 4x6</h5>

        <InputFile
          name="foto4x6"
          value={foto4x6}
          onChange={(file) => setFoto4x6(file)}
          max={300}
          maxValidation="300 KB"
        />

        <h5 className="mt-6 mb-3 text-lg font-medium">Pas Photo Ukuran 3x4</h5>

        <InputFile
          name="foto3x4"
          value={foto3x4}
          onChange={(file) => setFoto3x4(file)}
          max={300}
          maxValidation="300 KB"
        />

        <h5 className="mt-6 mb-3 text-lg font-medium">Pas Photo Ukuran 2x3</h5>

        <InputFile
          name="foto2x3"
          value={foto2x3}
          onChange={(file) => setFoto2x3(file)}
          max={300}
          maxValidation="300 KB"
        />

        <h5 className="mt-6 mb-3 text-lg font-medium">KTM Asli</h5>

        <InputFile
          name="ktm"
          value={ktm}
          onChange={(file) => setKtm(file)}
          accept="application/pdf"
          max={350}
          maxValidation="350 KB"
        />

        <h5 className="mt-6 mb-3 text-lg font-medium">
          Melampirkan Fotocopy Toefl dan memperlihatkan aslinya yang masih
          berlaku (Skor ≥ 500)
        </h5>

        <InputFile
          name="toefl"
          value={toefl}
          onChange={(file) => setToefl(file)}
          accept="application/pdf"
          max={300}
          maxValidation="300 KB"
        />

        <h5 className="mt-6 mb-3 text-lg font-medium">
          Surat bebas Pinjam Buku dari Perpustakaan Universitas
        </h5>

        <InputFile
          name="bebasPinjamBukuUniversitas"
          value={bebasPinjamBukuUniversitas}
          onChange={(file) => setBebasPinjamBukuUniversitas(file)}
          accept="application/pdf"
          max={300}
          maxValidation="300 KB"
        />

        <h5 className="mt-6 mb-3 text-lg font-medium">
          Surat bebas Pinjam Buku dari Perpustakaan Fakultas
        </h5>

        <InputFile
          name="bebasPinjamBukuFakultas"
          value={bebasPinjamBukuFakultas}
          onChange={(file) => setBebasPinjamBukuFakultas(file)}
          accept="application/pdf"
          max={300}
          maxValidation="300 KB"
        />

        <div className="my-10 flex items-center justify-between">
          <Button
            isSecondary
            className="border-2 border-accent-black !px-10 !text-accent-black"
            onClick={props.onBack}
          >
            Sebelumnya
          </Button>
          <Button isPrimary type="submit" className="!px-10">
            Berikutnya
          </Button>
        </div>
      </form>
    </Fade>
  );
};

export default RequirementsFile;

RequirementsFile.propTypes = {
  form: propTypes.object.isRequired,
  updateForm: propTypes.func.isRequired,
  onBack: propTypes.func.isRequired,
};
