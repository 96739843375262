import { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import propTypes from "prop-types";

// components
import Header from "components/Header";
import InputText from "components/Form/InputText";
import Button from "components/Button";

// assets
import ic_suitcase from "assets/icons/ic_suitcase.svg";

const Thesis = (props) => {
  let { form } = props;

  const [titleId, setTitleId] = useState("");
  const [titleEn, setTitleEn] = useState("");

  useEffect(() => {
    setTitleId(form.titleId);
    setTitleEn(form.titleEn);
  }, [form]);

  const submit = (e) => {
    e.preventDefault();

    form = {
      ...form,
      titleId,
      titleEn,
    };

    props.updateForm(form);
  };

  return (
    <Fade duration={750}>
      <form onSubmit={submit}>
        <Header
          icon={<img src={ic_suitcase} alt="Icon Suitcase" />}
          title="Informasi skripsi"
        />

        <h5 className="mt-6 mb-3 text-lg font-medium">
          Judul Skripsi Bahasa Indonesia
        </h5>

        <InputText
          name="titleId"
          placeholder="Judul Skripsi Bahasa Indonesia"
          value={titleId}
          onChange={(e) => setTitleId(e.target.value)}
        />

        <h5 className="mt-6 mb-3 text-lg font-medium">
          Judul Skripsi Bahasa Inggris
        </h5>

        <InputText
          name="titleEn"
          placeholder="Judul Skripsi Bahasa Inggris"
          value={titleEn}
          onChange={(e) => setTitleEn(e.target.value)}
        />

        <div className="my-10 flex items-center justify-between">
          <Button
            isSecondary
            className="border-2 border-accent-black !px-10 !text-accent-black"
            onClick={props.onBack}
          >
            Sebelumnya
          </Button>
          <Button isPrimary type="submit" className="!px-10">
            Berikutnya
          </Button>
        </div>
      </form>
    </Fade>
  );
};

export default Thesis;

Thesis.propTypes = {
  form: propTypes.object.isRequired,
  updateForm: propTypes.func.isRequired,
  onBack: propTypes.func.isRequired,
};
