import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";

// features
import {
  ActivationUser,
  ResendOtp,
  reset,
  resetMsg,
  resetSuccess,
} from "features/authSlice";

// services
import { confirmModal } from "services/general";

// components
import InputOtp from "components/Form/InputOtp";

// assets
import ArrowBack from "components/ArrowBack";
import Logo from "components/Logo";
import Copyright from "components/Copyright";

const Activation = () => {
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isSuccess, isLoading, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (message) toast.error(message);
    dispatch(resetMsg());
  }, [message, dispatch]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(resetSuccess());
      toast.success("Activation success");

      if (user.role.name === "mahasiswa") navigate("/mahasiswa/dashboard");
      else if (user.role.name === "dosen") navigate("/dosen/dashboard");
      else if (user.role.name === "dosen pembimbing")
        navigate("/dosen-pembimbing/dashboard");
      else if (user.role.name === "dosen penguji")
        navigate("/dosen-penguji/dashboard");
      else if (user.role.name === "akademik") navigate("/sba/dashboard");
      else if (user.role.name === "prodi") navigate("/prodi/dashboard");
      else if (user.role.name === "admin") navigate("/admin/dashboard");
    }
  }, [isSuccess, navigate]);

  const activationOtp = (value) => {
    dispatch(ActivationUser(value));
  };

  const resendOtp = () => {
    dispatch(ResendOtp());
  };

  const logout = () => {
    confirmModal().then((result) => {
      if (result.isConfirmed) {
        navigate("/");
        dispatch(reset());
        localStorage.removeItem("token");
        toast.success("Logout success");
      }
    });
  };

  return (
    <div className="bg-grid flex flex-col justify-between ">
      <div className="mt-10 flex w-full items-center justify-between">
        <ArrowBack onClick={logout} />

        <div>
          <Logo />
        </div>
      </div>

      {/* Form */}
      <form className="form-card mx-auto">
        <h1 className="text-xl font-semibold lg:text-4xl">Aktivasi Akun</h1>
        <h4 className="text-accent-gray text-base lg:text-xl">
          Lakukan aktivasi dengan cara memasukan kode OTP yang dikirimkan <br />
          ke email yang kamu daftarkan.
        </h4>

        <div className="mt-20 flex justify-center">
          <InputOtp
            value={otp}
            onChange={(value) => setOtp(value)}
            onComplete={(value) => activationOtp(value)}
            onResend={() => resendOtp()}
            email={user?.email}
          />
        </div>
      </form>

      {/* Copyright */}
      <Copyright />
    </div>
  );
};

export default Activation;
