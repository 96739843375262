import propTypes from "prop-types";
import { useSelector } from "react-redux";

// services
import {
  StatusReservation,
  renderDescriptionReservation,
  statusReservation,
  titleReservation,
} from "services/general";

// components
import Button from "components/Button";

// assets
import ic_eye from "assets/icons/ic_password_show.svg";
import ic_megaphone_green from "assets/icons/ic_megaphone_green.svg";
import ic_megaphone_red from "assets/icons/ic_megaphone_red.svg";
import ic_megaphone_yellow from "assets/icons/ic_megaphone_yellow.svg";

const ReservationItem = (props) => {
  const { data } = props;
  const { user } = useSelector((state) => state.auth);

  const renderIcon = () => {
    switch (statusReservation(data)) {
      case StatusReservation.accepted:
        return (
          <div className="icon !bg-light-green">
            <img src={ic_megaphone_green} alt="Icon Megaphone" />
          </div>
        );
      case StatusReservation.rejected:
        return (
          <div className="icon !bg-light-red">
            <img src={ic_megaphone_red} alt="Icon Megaphone" />
          </div>
        );
      case StatusReservation.pending:
        return (
          <div className="icon !bg-light-yellow">
            <img src={ic_megaphone_yellow} alt="Icon Megaphone" />
          </div>
        );
    }
  };

  const getSlugRole = () => {
    if (user?.role.name == "mahasiswa") return "mahasiswa";
    else if (user?.role.name == "dosen") return "dosen";
    else if (user?.role.name == "akademik") return "sba";
    else if (user?.role.name == "prodi") return "prodi";
    else if (user?.role.name == "admin") return "admin";
  };

  return (
    <div className="card my-5 flex flex-wrap items-center !justify-between gap-3 rounded-2xl">
      <div className="flex gap-3">
        {renderIcon()}

        <div>
          <h5 className="mb-1 text-lg font-medium">{titleReservation(data)}</h5>
          {renderDescriptionReservation(data)}
        </div>
      </div>

      <Button
        type="link"
        href={`/${getSlugRole()}/schedule/detail?id=${data.id}`}
        isInfo
        className="flex items-center gap-3 !rounded-lg !py-3"
      >
        <img src={ic_eye} alt="Icon Eye" />
        <h5 className="text-xl font-medium">Detail</h5>
      </Button>
    </div>
  );
};

export default ReservationItem;

ReservationItem.propTypes = {
  data: propTypes.object.isRequired,
};
