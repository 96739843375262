import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import moment from "moment";
import Calendar from "react-calendar";

// services
import {
  cancelReservation,
  checkSchedule,
  chooseExaminer,
  getReservation,
  getLecturesExaminerByProdi,
  changeDateReservation,
  changeSessionReservation,
} from "services/Reservations";
import {
  StatusReservation,
  confirmModal,
  descriptionReservation,
  getProdis,
  statusReservation,
  stepReservation,
  successModal,
} from "services/general";

// components
import TFade from "components/Transition/Fade";
import ArrowBack from "components/ArrowBack";
import InputText from "components/Form/InputText";
import Header from "components/Header";
import InputTextArea from "components/Form/InputTextArea";
import InputFileView from "components/Form/InputFileView";
import InputSelect from "components/Form/InputSelect";
import Modal from "components/Modal";
import Button from "components/Button";

// assets
import ic_calendar_small from "assets/icons/ic_calendar_small.svg";
import ic_male from "assets/icons/ic_male.svg";
import ic_microscope from "assets/icons/ic_microscope.svg";
import ic_suitcase from "assets/icons/ic_suitcase.svg";
import ic_folder from "assets/icons/ic_folder.svg";
import ic_happy from "assets/icons/ic_happy.svg";
import ic_cry from "assets/icons/ic_cry.svg";
import bgCircleYellow from "assets/images/bg-circle-yellow.svg";
import bgCircleRed from "assets/images/bg-circle-red.svg";
import bgCircleGreen from "assets/images/bg-circle-green.svg";
import ic_calendar from "assets/icons/ic_calendar.svg";
import ic_mic from "assets/icons/ic_mic.svg";

import "react-calendar/dist/Calendar.css";

const Schedule = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const steps = [
    {
      name: "Verifikasi Dokumen oleh SBA",
    },
    {
      name: "Pemilihan Dosen Penguji oleh Prodi",
    },
    {
      name: "Verifikasi Dosen Penguji 1 & 2",
    },
  ];
  const [currentStep, setCurrentStep] = useState(4);
  const [data, setData] = useState(null);

  const [showModalTolak, setShowModalTolak] = useState(false);
  const [showModalPenguji, setShowModalPenguji] = useState(false);
  const [showModalJadwal, setShowModalJadwal] = useState(false);
  const [showModalSesi, setShowModalSesi] = useState(false);

  const [alasan, setAlasan] = useState("");
  const [examinerProdiLecturer1, setExaminerProdiLecturer1] = useState("");
  const [examinerProdiLecturer2, setExaminerProdiLecturer2] = useState("");
  const [examinerLecturer1, setExaminerLecturer1] = useState("");
  const [examinerLecturer2, setExaminerLecturer2] = useState("");

  const [lectures1, setLectures1] = useState([]);
  const [lectures2, setLectures2] = useState([]);

  const [lecture1, setLecture1] = useState(null);
  const [lecture2, setLecture2] = useState(null);

  const [fieldsProdi1, setFieldsProdi1] = useState([
    {
      name: "Pilih Prodi",
      value: "",
    },
  ]);

  const [fieldsProdi2, setFieldsProdi2] = useState([
    {
      name: "Pilih Prodi",
      value: "",
    },
  ]);

  const [fieldsDosen1, setFieldsDosen1] = useState([
    {
      name: "Pilih Dosen",
      value: "",
    },
  ]);

  const [fieldsDosen2, setFieldsDosen2] = useState([
    {
      name: "Pilih Dosen",
      value: "",
    },
  ]);

  const [date, setDate] = useState(new Date());
  const [session, setSession] = useState("");

  const fieldsSession = [
    {
      name: "Pilih Sesi",
      value: "",
    },
    {
      name: "Sesi 1 (08.00 - 10.00)",
      value: 1,
    },
    {
      name: "Sesi 2 (10.00 - 12.00)",
      value: 2,
    },
    {
      name: "Sesi 3 (13.00 - 15.00)",
      value: 3,
    },
    {
      name: "Sesi 4 (15.00 - 17.00)",
      value: 4,
    },
  ];

  useEffect(() => {
    getData();
    getProdi();
  }, []);

  useEffect(() => {
    if (examinerProdiLecturer1) {
      getDosen1();
      setLecture1(null);
    }
  }, [examinerProdiLecturer1]);

  useEffect(() => {
    if (examinerProdiLecturer2) {
      getDosen2();
      setLecture2(null);
    }
  }, [examinerProdiLecturer2]);

  useEffect(() => {
    if (examinerLecturer1) {
      const lecture = lectures1.find(
        (lecture) => lecture.id === examinerLecturer1
      );
      setLecture1(lecture);
    }
  }, [examinerLecturer1]);

  useEffect(() => {
    if (examinerLecturer2) {
      const lecture = lectures2.find(
        (lecture) => lecture.id === examinerLecturer2
      );
      setLecture2(lecture);
    }
  }, [examinerLecturer2]);

  const renderHeader = () => {
    const status = statusReservation(data);

    switch (status) {
      case StatusReservation.accepted:
        return (
          <div className="relative z-0 mt-10 mb-6 flex flex-col justify-center overflow-hidden rounded-2xl bg-light-green px-8 py-10">
            <img
              src={bgCircleGreen}
              alt="Background Circle"
              className="absolute right-0 bottom-0 -z-10"
            />

            <h2 className="text-3xl font-semibold">
              {descriptionReservation(data)}
            </h2>

            <p>
              Reservasi jadwal ruangan sidang kamu telah disetujui, silahkan cek
              email kamu untuk informasi lebih lanjut.
            </p>
          </div>
        );
      case StatusReservation.canceled:
        return (
          <div className="relative z-0 mt-10 mb-6 flex flex-col justify-center overflow-hidden rounded-2xl bg-light-red px-8 py-10">
            <img
              src={bgCircleRed}
              alt="Background Circle"
              className="absolute right-0 bottom-0 -z-10"
            />

            <h2 className="text-3xl font-semibold">
              {descriptionReservation(data)}
            </h2>

            <p>
              Reservasi jadwal ruangan sidang kamu telah ditolak, silahkan cek
              email kamu untuk informasi lebih lanjut.
            </p>
          </div>
        );
      default:
        return (
          <div className="relative z-0 mt-10 mb-6 flex flex-col justify-center overflow-hidden rounded-2xl bg-cream px-8 py-10">
            <img
              src={bgCircleYellow}
              alt="Background Circle"
              className="absolute right-0 bottom-0 -z-10"
            />

            <h2 className="text-3xl font-semibold">
              {descriptionReservation(data)}
            </h2>

            <p>
              Reservasi jadwal ruangan sidang kamu sedang diproses, mohon tunggu
              sampai <br /> keputusan akhir keluar.
            </p>
          </div>
        );
    }
  };

  const getData = async () => {
    try {
      const response = await getReservation(searchParams.get("id"));
      const reservation = response.data.body.data.reservation;

      setData(reservation);
      setCurrentStep(stepReservation(reservation));
      setDate(reservation.date);
    } catch (error) {
      console.error(error);
    }
  };

  const getProdi = async () => {
    try {
      const response = await getProdis();
      const prodis = response.data.body.data.prodis;
      const newFields = [{ name: "Pilih Prodi", value: "" }];
      newFields.push(
        ...prodis.map((prodi) => ({
          name: prodi.name,
          value: prodi.id,
        }))
      );

      setFieldsProdi1(newFields);
      setFieldsProdi2(newFields);
    } catch (error) {
      console.error(error);
    }
  };

  const getDosen1 = async () => {
    try {
      const response = await getLecturesExaminerByProdi({
        prodi_id: examinerProdiLecturer1,
      });
      const lectures = response.data.body.data.lectures;
      const newFields = [{ name: "Pilih Dosen", value: "" }];
      newFields.push(
        ...lectures
          .filter((lecture) => lecture.id !== data.lecture_guidance)
          .map((lecture) => ({
            name: lecture.name,
            value: lecture.id,
          }))
      );

      setLectures1(lectures);
      setFieldsDosen1(newFields);
    } catch (error) {
      console.error(error);
    }
  };

  const getDosen2 = async () => {
    try {
      const response = await getLecturesExaminerByProdi({
        prodi_id: examinerProdiLecturer2,
      });
      const lectures = response.data.body.data.lectures;
      const newFields = [{ name: "Pilih Dosen", value: "" }];
      newFields.push(
        ...lectures
          .filter((lecture) => lecture.id !== data.lecture_guidance)
          .map((lecture) => ({
            name: lecture.name,
            value: lecture.id,
          }))
      );

      setLectures2(lectures);
      setFieldsDosen2(newFields);
    } catch (error) {
      console.error(error);
    }
  };

  const rejectReservation = (e) => {
    e.preventDefault();

    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await cancelReservation(searchParams.get("id"), { message: alasan });
          setShowModalTolak(false);
          successModal("Success", "Reservasi berhasil ditolak!");
          navigate("/prodi/dashboard");
        } catch (error) {
          toast.error(error.response.data.body.message);
          console.error(error);
        }
      }
    });
  };

  const approveExaminer = (e) => {
    e.preventDefault();

    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await chooseExaminer(searchParams.get("id"), {
            examiner_1: examinerLecturer1,
            examiner_2: examinerLecturer2,
          });
          setShowModalPenguji(false);
          successModal("Success", "Penguji berhasil disimpan!");
          navigate("/prodi/dashboard");
        } catch (error) {
          toast.error(error.response.data.body.message);
          console.error(error);
        }
      }
    });
  };

  const reschedule = (e) => {
    e.preventDefault();

    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await changeDateReservation(searchParams.get("id"), {
            date: moment(date).format("YYYY-MM-DD"),
            session,
          });
          setShowModalJadwal(false);
          successModal("Success", "Jadwal reservasi berhasil diubah!");
          navigate("/prodi/dashboard");
        } catch (error) {
          toast.error(error.response.data.body.message);
          console.error(error);
        }
      }
    });
  };

  const updateSession = (e) => {
    e.preventDefault();

    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await changeSessionReservation(searchParams.get("id"), {
            session,
          });
          setShowModalSesi(false);
          successModal("Success", "Sesi berhasil diubah!");
          navigate("/prodi/dashboard");
        } catch (error) {
          toast.error(error.response.data.body.message);
          console.error(error);
        }
      }
    });
  };

  return (
    <>
      <TFade>
        <ArrowBack />

        {data && (
          <>
            {renderHeader()}

            <ul className="step-wizard-list mb-6">
              {steps.map((step, index) => (
                <li
                  key={`step-${index}`}
                  className={`step-wizard-item ${
                    currentStep > index + 1
                      ? "success"
                      : currentStep === index + 1
                      ? "process"
                      : ""
                  }`}
                >
                  <div className="flex flex-col items-center">
                    <span className="progress-count">{index + 1}</span>
                    <span className="progress-label hidden w-36 text-center lg:block">
                      {step.name}
                    </span>
                  </div>
                </li>
              ))}
              <li
                className={`step-wizard-item ${
                  currentStep === 4 ? "success" : ""
                }`}
              >
                <div className="flex flex-col items-center">
                  <span className="progress-count">
                    <img src={ic_happy} alt="Icon Happy" />
                  </span>
                  <span className="progress-label hidden w-36 text-center lg:block">
                    Reservasi Kamu Disetujui
                  </span>
                </div>
              </li>

              <li
                className={`step-wizard-item ${
                  currentStep === 5 ? "failed" : ""
                }`}
              >
                <div className="flex flex-col items-center">
                  <span className="progress-count">
                    <img src={ic_cry} alt="Icon Cry" />
                  </span>
                  <span className="progress-label hidden w-36 text-center lg:block">
                    Reservasi Kamu Ditolak
                  </span>
                </div>
              </li>
            </ul>

            <Header
              icon={<img src={ic_calendar_small} alt="Icon Calendar" />}
              title="Tanggal Reservasi Ruangan"
            />

            <div className="mt-6 grid gap-5 md:grid-cols-2">
              <div>
                <h5 className="mb-3 text-lg font-medium">Tanggal Sidang</h5>

                <InputText
                  name="date"
                  placeholder="Tanggal Sidang"
                  value={data.date}
                  disabled
                />
              </div>

              <div>
                <h5 className="mb-3 text-lg font-medium">Sesi Sidang</h5>

                <InputText
                  name="session"
                  placeholder="Sesi Sidang"
                  value={
                    data.session === 0 ? "Waiting List" : `Sesi ${data.session}`
                  }
                  disabled
                />
              </div>
            </div>

            <Header
              icon={<img src={ic_male} alt="Icon Male" />}
              title="Identitas Diri"
              className="mt-10"
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Nama Lengkap(sesuai Akta Kelahiran)
            </h5>

            <InputText
              name="name"
              placeholder="Name"
              value={data.name_student}
              disabled
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">NPM</h5>

            <InputText
              name="npm"
              type="number"
              placeholder="NPM"
              value={data.npm_student}
              disabled
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">Alamat</h5>

            <InputTextArea
              name="address"
              placeholder="Address"
              value={data.address_student}
              disabled
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              No. Tlp yang Dapat Dihubungi
            </h5>

            <InputText
              name="phoneNumber"
              type="number"
              placeholder="Nomor Telepon"
              value={data.phone_student}
              disabled
            />

            <Header
              icon={<img src={ic_microscope} alt="Icon Microscope" />}
              title="Informasi Bimbingan"
              className="mt-10"
            />

            <div className="mt-6 grid gap-5 md:grid-cols-2">
              <div>
                <h5 className="mb-3 text-lg font-medium">
                  Tanggal Mulai Bimbingan
                </h5>

                <InputText
                  name="guidanceStartDate"
                  value={data.date_start_guidance}
                  placeholder="Tanggal Mulai Bimbingan"
                  disabled
                />
              </div>

              <div>
                <h5 className="mb-3 text-lg font-medium">
                  Tanggal Akhir Bimbingan
                </h5>

                <InputText
                  name="guidanceEndDate"
                  value={data.date_end_guidance}
                  placeholder="Tanggal Akhir Bimbingan"
                  disabled
                />
              </div>

              <div>
                <h5 className="mb-3 text-lg font-medium">
                  Prodi Dosen Pembimbing
                </h5>

                <InputText
                  name="guidanceStudyProgram"
                  value={data.lecture_guide?.prodi.name}
                  disabled
                />
              </div>

              <div>
                <h5 className="mb-3 text-lg font-medium">Dosen Pembimbing</h5>

                <InputText
                  name="guidanceLecturer"
                  value={data.lecture_guide.name}
                  disabled
                />
              </div>
            </div>

            <Header
              icon={<img src={ic_suitcase} alt="Icon Suitcase" />}
              title="Informasi skripsi"
              className="mt-10"
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Judul Skripsi Bahasa Indonesia
            </h5>

            <InputText
              name="titleId"
              placeholder="Judul Skripsi Bahasa Indonesia"
              value={data.title_skripsi_indonesia}
              disabled
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Judul Skripsi Bahasa Inggris
            </h5>

            <InputText
              name="titleEn"
              placeholder="Judul Skripsi Bahasa Inggris"
              value={data.title_skripsi_english}
              disabled
            />

            <Header
              icon={<img src={ic_folder} alt="Icon Folder" />}
              title="Berkas Persyaratan"
              className="mt-10"
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Melampirkan Surat Tugas dan Jadwal Bimbingan Skripsi yang sudah
              disetujui Pembimbing dan ditandatangani Ketua Program Studi
            </h5>

            <InputFileView
              name="suratTugasJadwalBimbingan"
              value={data.surat_tugas_jadwal_bimbingan}
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Melampirkan lembar validasi similarity pengecekan Turnitin skripsi
              yang ditandatangan petugas perpustakaan
            </h5>

            <InputFileView
              name="validasiSimilarity"
              value={data.lembar_validasi_similarity}
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Transkrip Akademik yang sudah ditandatangani oleh Wakil Dekan I
            </h5>

            <InputFileView
              name="transkripAkademik"
              value={data.transkrip_akademik}
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Tanda Bukti Pembayaran Uang Kuliah/Registrasi Terakhir
            </h5>

            <InputFileView
              name="pembayaranUangKuliah"
              value={data.bukti_pembayaran_kuliah}
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">KRS Terakhir</h5>

            <InputFileView name="krsTerakhir" value={data.krs_terakhir} />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Menyerahkan Draft Skripsi yang telah ditanda tangani oleh
              pembimbing sebanyak 4 eksemplar bagi 1 Pembimbing dan 5 eksemplar
              bagi 2 Pembimbing dengan melampirkan Surat Pernyataan Keaslian
              Karya Ilmiah bermaterai Rp 6000,-
            </h5>

            <InputFileView name="draftSkripsi" value={data.draft_skripsi} />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Menyerahkan File Presentasi Sidang Skripsi
            </h5>

            <InputFileView name="filePresentasi" value={data.file_presentasi} />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Menyerahkan lembar selesai revisi dari Seminar Usulan Penelitian
              Skripsi yang ditandatangan Pembimbing
            </h5>

            <InputFileView
              name="selesaiRevisi"
              value={data.lembar_selesai_revisi}
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Menyerahkan sedikitnya 2 (dua) buah foto bukti menghadiri Seminar
              UP, Sidang Skripsi atau Diseminasi Paper Terpublikasi
            </h5>

            <InputFileView
              name="buktiMenghadiri"
              value={data.foto_bukti_seminar}
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Pas Photo Ukuran 4x6
            </h5>

            <InputFileView name="foto4x6" value={data.pas_photo_4x6} />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Pas Photo Ukuran 3x4
            </h5>

            <InputFileView name="foto3x4" value={data.pas_photo_3x4} />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Pas Photo Ukuran 2x3
            </h5>

            <InputFileView name="foto2x3" value={data.pas_photo_2x3} />

            <h5 className="mt-6 mb-3 text-lg font-medium">KTM Asli</h5>

            <InputFileView name="ktm" value={data.ktm} />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Melampirkan Fotocopy Toefl dan memperlihatkan aslinya yang masih
              berlaku (Skor ≥ 500)
            </h5>

            <InputFileView name="toefl" value={data.toefl} />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Surat bebas Pinjam Buku dari Perpustakaan Universitas
            </h5>

            <InputFileView
              name="bebasPinjamBukuUniversitas"
              value={data.surat_bebas_pinjam_buku_universitas}
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Surat bebas Pinjam Buku dari Perpustakaan Fakultas
            </h5>

            <InputFileView
              name="bebasPinjamBukuFakultas"
              value={data.surat_bebas_pinjam_buku_fakultas}
            />

            {statusReservation(data, "prodi") === StatusReservation.pending ? (
              <div className="my-10 flex items-center justify-end gap-5">
                <Button
                  isDanger
                  className="!px-10"
                  onClick={() => setShowModalTolak(true)}
                >
                  Tolak Sidang
                </Button>
                {data.session === 0 && (
                  <Button
                    isSecondary
                    className="!px-10"
                    onClick={() => setShowModalSesi(true)}
                  >
                    Rubah Sesi
                  </Button>
                )}
                <Button
                  isInfo
                  className="!px-10"
                  onClick={() => setShowModalJadwal(true)}
                >
                  Rubah Jadwal
                </Button>
                {data.session !== 0 && (
                  <Button
                    isSuccess
                    className="!px-10"
                    onClick={() => setShowModalPenguji(true)}
                  >
                    Pilih Penguji
                  </Button>
                )}
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </TFade>
      <Modal
        name="Alasan Pembatalan Sidang"
        showModal={showModalTolak}
        setShowModal={(value) => setShowModalTolak(value)}
        body={
          <form onSubmit={rejectReservation}>
            <InputTextArea
              name="alasan"
              placeholder="Tulis disini..."
              value={alasan}
              inputClassName="h-[200px]"
              onChange={(e) => setAlasan(e.target.value)}
            />

            <div className="mt-8 flex justify-end">
              <Button isPrimary type="submit" className="!px-10">
                Kirim
              </Button>
            </div>
          </form>
        }
      />

      <Modal
        name="Pilih Penguji Sidang"
        showModal={showModalPenguji}
        setShowModal={(value) => setShowModalPenguji(value)}
        body={
          <form onSubmit={approveExaminer}>
            <div className="grid grid-cols-2 gap-5">
              <div>
                <h5 className="mb-3 text-lg font-medium">
                  Prodi Dosen Penguji 1
                </h5>

                <InputSelect
                  name="examinerProdiLecturer1"
                  value={examinerProdiLecturer1}
                  fields={fieldsProdi1}
                  onChange={(e) => setExaminerProdiLecturer1(e.target.value)}
                  required
                />
              </div>

              <div>
                <h5 className="mb-3 text-lg font-medium">
                  Prodi Dosen Penguji 2
                </h5>

                <InputSelect
                  name="examinerProdiLecturer2"
                  value={examinerProdiLecturer2}
                  fields={fieldsProdi2}
                  onChange={(e) => setExaminerProdiLecturer2(e.target.value)}
                  required
                />
              </div>

              <div>
                <h5 className="mb-3 text-lg font-medium">Dosen Penguji 1</h5>

                <InputSelect
                  name="examinerLecturer1"
                  value={examinerLecturer1}
                  fields={fieldsDosen1}
                  onChange={(e) => setExaminerLecturer1(e.target.value)}
                  required
                />
              </div>

              <div>
                <h5 className="mb-3 text-lg font-medium">Dosen Penguji 2</h5>

                <InputSelect
                  name="examinerLecturer2"
                  value={examinerLecturer2}
                  fields={fieldsDosen2}
                  onChange={(e) => setExaminerLecturer2(e.target.value)}
                  required
                />
              </div>

              <div>
                {lecture1 && (
                  <div className="rounded-2xl border-2 border-accent-black bg-[#1010100D] p-4">
                    <div className="flex items-center gap-3">
                      <img
                        src={`${process.env.REACT_APP_STORAGE}avatars/${lecture1.avatar}`}
                        alt="Avatar"
                        className="h-[70px] w-[70px] rounded-full border-2 border-accent-black object-cover p-1"
                      />

                      <div>
                        <h5 className="text-base font-semibold">
                          {lecture1?.name}
                        </h5>
                        <span className="font-medium capitalize text-primary-yellow">
                          {lecture1.prodi.name}
                        </span>
                        <span className="block font-medium capitalize">
                          {lecture1.email} | {lecture1.phone}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div>
                {lecture2 && (
                  <div className="rounded-2xl border-2 border-accent-black bg-[#1010100D] p-4">
                    <div className="flex items-center gap-3">
                      <img
                        src={`${process.env.REACT_APP_STORAGE}avatars/${lecture2.avatar}`}
                        alt="Avatar"
                        className="h-[70px] w-[70px] rounded-full border-2 border-accent-black object-cover p-1"
                      />

                      <div>
                        <h5 className="text-base font-semibold">
                          {lecture2?.name}
                        </h5>
                        <span className="font-medium capitalize text-primary-yellow">
                          {lecture2.prodi.name}
                        </span>
                        <span className="block font-medium capitalize">
                          {lecture2.email} | {lecture2.phone}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="mt-8 flex justify-end">
              <Button isPrimary type="submit" className="!px-10">
                Kirim
              </Button>
            </div>
          </form>
        }
      />

      <Modal
        name="Rubah Jadwal Reservasi"
        showModal={showModalJadwal}
        setShowModal={(value) => setShowModalJadwal(value)}
        body={
          <form onSubmit={reschedule}>
            <Header
              icon={<img src={ic_calendar} alt="Icon Calendar" />}
              title="Tanggal Reservasi Ruangan"
            />

            <div className="card my-4 rounded-2xl">
              <Calendar
                onChange={(value) => setDate(value)}
                value={date}
                className="!w-full border-none"
              />
            </div>

            <h5 className="my-4 text-lg font-medium">Sesi Sidang</h5>

            <InputSelect
              name="session"
              value={session}
              fields={fieldsSession}
              onChange={(e) => setSession(e.target.value)}
            />

            <div className="mt-5 flex flex-wrap items-center justify-between gap-3">
              <Button isPrimary type="submit" className="!px-10">
                Submit
              </Button>
            </div>
          </form>
        }
      />
      <Modal
        name="Perubahan Sesi Sidang"
        showModal={showModalSesi}
        setShowModal={(value) => setShowModalSesi(value)}
        body={
          <form onSubmit={updateSession}>
            <h5 className="my-4 text-lg font-medium">Sesi Sidang</h5>

            <InputSelect
              name="session"
              value={session}
              fields={fieldsSession}
              onChange={(e) => setSession(e.target.value)}
            />

            <div className="mt-5 flex flex-wrap items-center justify-end gap-3">
              <Button isPrimary type="submit" className="!px-10">
                Submit
              </Button>
            </div>
          </form>
        }
      />
    </>
  );
};

export default Schedule;
