import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

// features
import {
  LoginUser,
  ChangeStatusLogin,
  reset,
  resetMsg,
  resetSuccess,
} from "features/authSlice";

// components
import InputText from "components/Form/InputText";
import InputPassword from "components/Form/InputPassword";
import Button from "components/Button";
import Logo from "components/Logo";
import Copyright from "components/Copyright";
import ArrowBack from "components/ArrowBack";

// assets
import meditating from "assets/images/meditating.svg";

const LoginOther = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isSuccess, isLoading, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (message) toast.error(message);
    dispatch(resetMsg());
  }, [message, dispatch]);

  useEffect(() => {
    if (user) {
      dispatch(resetSuccess());

      if (user.status == "disabled") {
        navigate("/activation");
        return;
      }

      toast.success("Login success");

      if (user.role.name === "mahasiswa") navigate("/mahasiswa/dashboard");
      else if (user.role.name === "dosen") navigate("/dosen/dashboard");
      else if (user.role.name === "dosen pembimbing")
        navigate("/dosen-pembimbing/dashboard");
      else if (user.role.name === "dosen penguji")
        navigate("/dosen-penguji/dashboard");
      else if (user.role.name === "akademik") navigate("/sba/dashboard");
      else if (user.role.name === "prodi") navigate("/prodi/dashboard");
      else if (user.role.name === "admin") navigate("/admin/dashboard");

      return;
    }

    dispatch(reset());
  }, [user, dispatch, navigate]);

  const Auth = (e) => {
    e.preventDefault();
    dispatch(LoginUser({ email, password }));
  };

  return (
    <div className="bg-grid flex flex-col justify-between ">
      <div className="mt-10 flex w-full items-center justify-between">
        <ArrowBack />

        <div>
          <Logo />
        </div>
      </div>

      <div className="flex items-center">
        {/* Form */}
        <form onSubmit={Auth} className="w-full lg:w-1/2">
          <h1 className="text-xl font-semibold lg:text-4xl">
            Masuk Akun Lainnya
          </h1>
          <h4 className="text-accent-gray text-base lg:text-xl">
            Masuk khusus akun dosen pembimbing, dosen penguji, prodi, <br /> dan
            akademik.
          </h4>

          <div className="mt-6">
            <InputText
              name="email"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onDelete={() => setEmail("")}
            />

            <InputPassword
              name="password"
              placeholder="Password"
              inputClassName="mt-5"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button
              isPrimary
              isBlock
              // isDisabled={email === "" || password === ""}
              type="submit"
              className="mt-5"
              isLoading={isLoading}
            >
              Masuk Akun
            </Button>
          </div>
        </form>

        <div className="hidden items-center justify-center lg:flex lg:w-1/2">
          <img src={meditating} alt="Meditating" />
        </div>
      </div>

      {/* Copyright */}
      <Copyright />
    </div>
  );
};

export default LoginOther;
