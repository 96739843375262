import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

// services
import {
  acceptReservationExaminer,
  cancelReservation,
  getReservation,
} from "services/Reservations";
import {
  StatusReservation,
  confirmModal,
  descriptionReservation,
  statusReservation,
  stepReservation,
  successModal,
} from "services/general";

// components
import TFade from "components/Transition/Fade";
import ArrowBack from "components/ArrowBack";
import InputText from "components/Form/InputText";
import Header from "components/Header";
import InputTextArea from "components/Form/InputTextArea";
import InputFileView from "components/Form/InputFileView";
import Modal from "components/Modal";
import Button from "components/Button";

// assets
import ic_calendar_small from "assets/icons/ic_calendar_small.svg";
import ic_male from "assets/icons/ic_male.svg";
import ic_microscope from "assets/icons/ic_microscope.svg";
import ic_suitcase from "assets/icons/ic_suitcase.svg";
import ic_folder from "assets/icons/ic_folder.svg";
import ic_happy from "assets/icons/ic_happy.svg";
import ic_cry from "assets/icons/ic_cry.svg";
import bgCircleYellow from "assets/images/bg-circle-yellow.svg";
import bgCircleRed from "assets/images/bg-circle-red.svg";
import bgCircleGreen from "assets/images/bg-circle-green.svg";

const Schedule = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const steps = [
    {
      name: "Verifikasi Dokumen oleh SBA",
    },
    {
      name: "Pemilihan Dosen Penguji oleh Prodi",
    },
    {
      name: "Verifikasi Dosen Penguji 1 & 2",
    },
  ];
  const [currentStep, setCurrentStep] = useState(4);
  const [data, setData] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await getReservation(searchParams.get("id"));
      setData(response.data.body.data.reservation);
      setCurrentStep(stepReservation(response.data.body.data.reservation));
    } catch (error) {
      console.error(error);
    }
  };

  const [showModalTolak, setShowModalTolak] = useState(false);
  const [showModalAcc, setShowModalAcc] = useState(false);

  const [alasan, setAlasan] = useState("");

  const renderHeader = () => {
    const status = statusReservation(data);

    switch (status) {
      case StatusReservation.accepted:
        return (
          <div className="relative z-0 mt-10 mb-6 flex flex-col justify-center overflow-hidden rounded-2xl bg-light-green px-8 py-10">
            <img
              src={bgCircleGreen}
              alt="Background Circle"
              className="absolute right-0 bottom-0 -z-10"
            />

            <h2 className="text-3xl font-semibold">
              {descriptionReservation(data)}
            </h2>

            <p>
              Reservasi jadwal ruangan sidang kamu telah disetujui, silahkan cek
              email kamu untuk informasi lebih lanjut.
            </p>
          </div>
        );
      case StatusReservation.canceled:
        return (
          <div className="relative z-0 mt-10 mb-6 flex flex-col justify-center overflow-hidden rounded-2xl bg-light-red px-8 py-10">
            <img
              src={bgCircleRed}
              alt="Background Circle"
              className="absolute right-0 bottom-0 -z-10"
            />

            <h2 className="text-3xl font-semibold">
              {descriptionReservation(data)}
            </h2>

            <p>
              Reservasi jadwal ruangan sidang kamu telah ditolak, silahkan cek
              email kamu untuk informasi lebih lanjut.
            </p>
          </div>
        );
      default:
        return (
          <div className="relative z-0 mt-10 mb-6 flex flex-col justify-center overflow-hidden rounded-2xl bg-cream px-8 py-10">
            <img
              src={bgCircleYellow}
              alt="Background Circle"
              className="absolute right-0 bottom-0 -z-10"
            />

            <h2 className="text-3xl font-semibold">
              {descriptionReservation(data)}
            </h2>

            <p>
              Reservasi jadwal ruangan sidang kamu sedang diproses, mohon tunggu
              sampai <br /> keputusan akhir keluar.
            </p>
          </div>
        );
    }
  };

  const renderStatusExaminer = (status) => {
    return (
      <div
        className={`flex h-12 w-12 items-center justify-center rounded-full ${
          status ? "bg-[#24FF0026]" : "bg-[#FF56781A]"
        }`}
      >
        {status ? (
          <svg
            width="31"
            height="22"
            viewBox="0 0 31 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.15585 19.9229C8.54158 19.5497 8.75348 19.361 8.94746 19.1547C15.1664 12.5411 21.9772 6.58703 28.8283 0.678107C29.0871 0.454939 29.3523 0.170246 29.6588 0.0904688C30.0282 -0.00582031 30.6307 -0.0690934 30.7959 0.13513C31.0178 0.410093 31.0571 1.00781 30.9219 1.36916C30.7608 1.79898 30.3686 2.1671 30.0128 2.48899C27.8893 4.40956 25.7064 6.26408 23.6202 8.22463C19.2594 12.324 14.9264 16.4539 10.6212 20.6143C8.7347 22.4335 8.00867 22.5072 6.27281 20.5634C5.13014 19.2833 1.68163 14.969 0.625529 13.6099C0.476389 13.3986 0.343541 13.1758 0.228185 12.9437C-0.0108682 12.5135 -0.165746 12.0399 0.307578 11.7063C0.835031 11.3351 1.2095 11.7225 1.52198 12.1259C1.83838 12.5344 2.10817 12.9831 2.44694 13.3702C3.4986 14.5719 7.02001 18.656 8.15585 19.9229Z"
              fill="#00FF85"
            />
          </svg>
        ) : (
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_135_433)">
              <path
                d="M10.5412 13.1759C8.62777 14.9159 6.75659 16.6013 4.90413 18.3077C3.83096 19.2962 2.79621 20.3276 1.72554 21.3188C1.44037 21.5895 1.11269 21.8096 0.755993 21.9699C0.580101 22.0449 0.200277 21.9699 0.113657 21.8325C-0.000952378 21.6501 0.032933 21.3264 0.0950991 21.0884C0.140324 20.9148 0.324465 20.7712 0.463381 20.6318C3.10519 17.9897 5.74821 15.3493 8.39238 12.7106C8.68907 12.4146 8.99429 12.1277 9.33517 11.799C8.17965 10.4982 7.02233 9.243 5.91748 7.94115C4.15415 5.86244 2.4262 3.75281 0.683631 1.6561C0.620287 1.5799 0.565798 1.49649 0.501864 1.42029C0.196926 1.05414 -0.0180568 0.659495 0.32916 0.215343C0.589167 -0.117209 1.21073 -0.0675395 1.64354 0.384863C2.72143 1.51122 3.77143 2.66487 4.81263 3.82737C6.71385 5.94883 8.60024 8.08409 10.4947 10.2118C10.5758 10.2892 10.662 10.3609 10.7528 10.4262C12.2176 9.00404 13.6663 7.58652 15.1271 6.18117C16.8212 4.55216 18.5212 2.92957 20.2272 1.31336C20.8671 0.705855 21.3802 0.596356 21.773 0.950807C22.18 1.31801 22.1421 1.93149 21.493 2.58924C19.7382 4.3678 17.941 6.10317 16.1456 7.84047C14.7778 9.16438 13.3882 10.4655 11.9324 11.8483C13.1153 13.0911 14.2467 14.3298 15.4329 15.5112C16.5722 16.646 17.7752 17.7149 18.9369 18.8262C19.3911 19.2445 19.8163 19.6941 20.2098 20.1719C20.5271 20.5728 20.574 21.0705 20.1839 21.4659C19.7995 21.8559 19.3545 21.7181 18.9837 21.4229C18.2745 20.8876 17.5946 20.3131 16.9472 19.7019C14.9654 17.7305 13.0186 15.7229 11.059 13.7283C10.9041 13.5708 10.756 13.4055 10.5412 13.1759Z"
                fill="#EA4335"
              />
            </g>
            <defs>
              <clipPath id="clip0_135_433">
                <rect width="22" height="22" fill="white" />
              </clipPath>
            </defs>
          </svg>
        )}
      </div>
    );
  };

  const acceptReservation = (e) => {
    e.preventDefault();

    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await acceptReservationExaminer(searchParams.get("id"));
          successModal("Success", "Reservasi berhasil disetujui!");
          setShowModalAcc(false);
          navigate("/dosen-penguji/dashboard");
        } catch (error) {
          toast.error(error.response.data.body.message);
          console.error(error);
        }
      }
    });
  };

  const rejectReservation = (e) => {
    e.preventDefault();

    confirmModal().then(async (result) => {
      if (result.isConfirmed) {
        try {
          await cancelReservation(searchParams.get("id"), { message: alasan });
          setShowModalTolak(false);
          successModal("Success", "Reservasi berhasil ditolak!");
          navigate("/dosen-penguji/dashboard");
        } catch (error) {
          toast.error(error.response.data.body.message);
          console.error(error);
        }
      }
    });
  };

  return (
    <>
      <TFade>
        <ArrowBack />

        {data && (
          <>
            {renderHeader()}

            <ul className="step-wizard-list mb-6">
              {steps.map((step, index) => (
                <li
                  key={`step-${index}`}
                  className={`step-wizard-item ${
                    currentStep > index + 1
                      ? "success"
                      : currentStep === index + 1
                      ? "process"
                      : ""
                  }`}
                >
                  <div className="flex flex-col items-center">
                    <span className="progress-count">{index + 1}</span>
                    <span className="progress-label hidden w-36 text-center lg:block">
                      {step.name}
                    </span>
                  </div>
                </li>
              ))}
              <li
                className={`step-wizard-item ${
                  currentStep === 4 ? "success" : ""
                }`}
              >
                <div className="flex flex-col items-center">
                  <span className="progress-count">
                    <img src={ic_happy} alt="Icon Happy" />
                  </span>
                  <span className="progress-label hidden w-36 text-center lg:block">
                    Reservasi Kamu Disetujui
                  </span>
                </div>
              </li>

              <li
                className={`step-wizard-item ${
                  currentStep === 5 ? "failed" : ""
                }`}
              >
                <div className="flex flex-col items-center">
                  <span className="progress-count">
                    <img src={ic_cry} alt="Icon Cry" />
                  </span>
                  <span className="progress-label hidden w-36 text-center lg:block">
                    Reservasi Kamu Ditolak
                  </span>
                </div>
              </li>
            </ul>

            <Header
              icon={<img src={ic_calendar_small} alt="Icon Calendar" />}
              title="Tanggal Reservasi Ruangan"
            />

            <div className="mt-6 grid gap-5 md:grid-cols-2">
              <div>
                <h5 className="mb-3 text-lg font-medium">Tanggal Sidang</h5>

                <InputText
                  name="date"
                  placeholder="Tanggal Sidang"
                  value={data.date}
                  disabled
                />
              </div>

              <div>
                <h5 className="mb-3 text-lg font-medium">Sesi Sidang</h5>

                <InputText
                  name="session"
                  placeholder="Sesi Sidang"
                  value={
                    data.session === 0 ? "Waiting List" : `Sesi ${data.session}`
                  }
                  disabled
                />
              </div>
            </div>

            <Header
              icon={<img src={ic_male} alt="Icon Male" />}
              title="Identitas Diri"
              className="mt-10"
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Nama Lengkap(sesuai Akta Kelahiran)
            </h5>

            <InputText
              name="name"
              placeholder="Name"
              value={data.name_student}
              disabled
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">NPM</h5>

            <InputText
              name="npm"
              type="number"
              placeholder="NPM"
              value={data.npm_student}
              disabled
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">Alamat</h5>

            <InputTextArea
              name="address"
              placeholder="Address"
              value={data.address_student}
              disabled
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              No. Tlp yang Dapat Dihubungi
            </h5>

            <InputText
              name="phoneNumber"
              type="number"
              placeholder="Nomor Telepon"
              value={data.phone_student}
              disabled
            />

            <Header
              icon={<img src={ic_microscope} alt="Icon Microscope" />}
              title="Informasi Bimbingan"
              className="mt-10"
            />

            <div className="mt-6 grid gap-5 md:grid-cols-2">
              <div>
                <h5 className="mb-3 text-lg font-medium">
                  Tanggal Mulai Bimbingan
                </h5>

                <InputText
                  name="guidanceStartDate"
                  value={data.date_start_guidance}
                  placeholder="Tanggal Mulai Bimbingan"
                  disabled
                />
              </div>

              <div>
                <h5 className="mb-3 text-lg font-medium">
                  Tanggal Akhir Bimbingan
                </h5>

                <InputText
                  name="guidanceEndDate"
                  value={data.date_end_guidance}
                  placeholder="Tanggal Akhir Bimbingan"
                  disabled
                />
              </div>

              <div>
                <h5 className="mb-3 text-lg font-medium">
                  Prodi Dosen Pembimbing
                </h5>

                <InputText
                  name="guidanceStudyProgram"
                  value={data.lecture_guide?.prodi.name}
                  disabled
                />
              </div>

              <div>
                <h5 className="mb-3 text-lg font-medium">Dosen Pembimbing</h5>

                <InputText
                  name="guidanceLecturer"
                  value={data.lecture_guide.name}
                  disabled
                />
              </div>
            </div>

            <Header
              icon={<img src={ic_suitcase} alt="Icon Suitcase" />}
              title="Informasi skripsi"
              className="mt-10"
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Judul Skripsi Bahasa Indonesia
            </h5>

            <InputText
              name="titleId"
              placeholder="Judul Skripsi Bahasa Indonesia"
              value={data.title_skripsi_indonesia}
              disabled
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Judul Skripsi Bahasa Inggris
            </h5>

            <InputText
              name="titleEn"
              placeholder="Judul Skripsi Bahasa Inggris"
              value={data.title_skripsi_english}
              disabled
            />

            <Header
              icon={<img src={ic_folder} alt="Icon Folder" />}
              title="Berkas Persyaratan"
              className="mt-10"
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Melampirkan Surat Tugas dan Jadwal Bimbingan Skripsi yang sudah
              disetujui Pembimbing dan ditandatangani Ketua Program Studi
            </h5>

            <InputFileView
              name="suratTugasJadwalBimbingan"
              value={data.surat_tugas_jadwal_bimbingan}
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Melampirkan lembar validasi similarity pengecekan Turnitin skripsi
              yang ditandatangan petugas perpustakaan
            </h5>

            <InputFileView
              name="validasiSimilarity"
              value={data.lembar_validasi_similarity}
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Transkrip Akademik yang sudah ditandatangani oleh Wakil Dekan I
            </h5>

            <InputFileView
              name="transkripAkademik"
              value={data.transkrip_akademik}
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Tanda Bukti Pembayaran Uang Kuliah/Registrasi Terakhir
            </h5>

            <InputFileView
              name="pembayaranUangKuliah"
              value={data.bukti_pembayaran_kuliah}
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">KRS Terakhir</h5>

            <InputFileView name="krsTerakhir" value={data.krs_terakhir} />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Menyerahkan Draft Skripsi yang telah ditanda tangani oleh
              pembimbing sebanyak 4 eksemplar bagi 1 Pembimbing dan 5 eksemplar
              bagi 2 Pembimbing dengan melampirkan Surat Pernyataan Keaslian
              Karya Ilmiah bermaterai Rp 6000,-
            </h5>

            <InputFileView name="draftSkripsi" value={data.draft_skripsi} />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Menyerahkan File Presentasi Sidang Skripsi
            </h5>

            <InputFileView name="filePresentasi" value={data.file_presentasi} />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Menyerahkan lembar selesai revisi dari Seminar Usulan Penelitian
              Skripsi yang ditandatangan Pembimbing
            </h5>

            <InputFileView
              name="selesaiRevisi"
              value={data.lembar_selesai_revisi}
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Menyerahkan sedikitnya 2 (dua) buah foto bukti menghadiri Seminar
              UP, Sidang Skripsi atau Diseminasi Paper Terpublikasi
            </h5>

            <InputFileView
              name="buktiMenghadiri"
              value={data.foto_bukti_seminar}
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Pas Photo Ukuran 4x6
            </h5>

            <InputFileView name="foto4x6" value={data.pas_photo_4x6} />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Pas Photo Ukuran 3x4
            </h5>

            <InputFileView name="foto3x4" value={data.pas_photo_3x4} />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Pas Photo Ukuran 2x3
            </h5>

            <InputFileView name="foto2x3" value={data.pas_photo_2x3} />

            <h5 className="mt-6 mb-3 text-lg font-medium">KTM Asli</h5>

            <InputFileView name="ktm" value={data.ktm} />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Melampirkan Fotocopy Toefl dan memperlihatkan aslinya yang masih
              berlaku (Skor ≥ 500)
            </h5>

            <InputFileView name="toefl" value={data.toefl} />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Surat bebas Pinjam Buku dari Perpustakaan Universitas
            </h5>

            <InputFileView
              name="bebasPinjamBukuUniversitas"
              value={data.surat_bebas_pinjam_buku_universitas}
            />

            <h5 className="mt-6 mb-3 text-lg font-medium">
              Surat bebas Pinjam Buku dari Perpustakaan Fakultas
            </h5>

            <InputFileView
              name="bebasPinjamBukuFakultas"
              value={data.surat_bebas_pinjam_buku_fakultas}
            />

            {data?.examiner_1.name == user?.name ||
            data?.examiner_2.name == user?.name ? (
              statusReservation(
                data,
                data?.examiner_1.name == user?.name
                  ? "dosen-penguji-1"
                  : "dosen-penguji-2"
              ) === StatusReservation.pending ? (
                <div className="my-10 flex items-center justify-end gap-5">
                  <Button
                    isDanger
                    className="!px-10"
                    onClick={() => setShowModalTolak(true)}
                  >
                    Tolak Sidang
                  </Button>
                  <Button
                    isSuccess
                    className="!px-10"
                    onClick={() => setShowModalAcc(true)}
                  >
                    Acc Sidang
                  </Button>
                </div>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </>
        )}
      </TFade>
      <Modal
        name="Alasan Pembatalan Sidang"
        showModal={showModalTolak}
        setShowModal={(value) => setShowModalTolak(value)}
        body={
          <form onSubmit={rejectReservation}>
            <InputTextArea
              name="alasan"
              placeholder="Tulis disini..."
              value={alasan}
              inputClassName="h-[200px]"
              onChange={(e) => setAlasan(e.target.value)}
            />

            <div className="mt-8 flex justify-end">
              <Button isPrimary type="submit" className="!px-10">
                Kirim
              </Button>
            </div>
          </form>
        }
      />
      <Modal
        name="Acc Jadwal Sidang"
        showModal={showModalAcc}
        setShowModal={(value) => setShowModalAcc(value)}
        body={
          <form onSubmit={acceptReservation}>
            <div className="grid grid-cols-2 gap-5">
              <div className="flex items-center gap-3">
                {renderStatusExaminer(data?.verification_lecture1)}

                <div>
                  <h3 className="text-xl font-semibold">Dosen Penguji 1</h3>
                  <h4 className="font-medium">
                    {data?.examiner_1.name}{" "}
                    {data?.examiner_1.name == user?.name ? "(Anda)" : ""}
                  </h4>
                </div>
              </div>

              <div className="flex items-center gap-3">
                {renderStatusExaminer(data?.verification_lecture2)}

                <div>
                  <h3 className="text-xl font-semibold">Dosen Penguji 2</h3>
                  <h4 className="font-medium">
                    {data?.examiner_2.name}{" "}
                    {data?.examiner_2.name == user?.name ? "(Anda)" : ""}
                  </h4>
                </div>
              </div>
            </div>

            <div className="mt-8 flex justify-end">
              <Button isPrimary type="submit" className="!px-10">
                Kirim
              </Button>
            </div>
          </form>
        }
      />
    </>
  );
};

export default Schedule;
