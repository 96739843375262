import axios from "axios";
import moment from "moment";
import headers from "services/Headers";

const getNotifications = (start, end) => {
  const dateStart = moment(start).format("YYYY-MM-DD");
  const dateEnd = moment(end).format("YYYY-MM-DD");

  return axios.get(
    `${process.env.REACT_APP_API}/notifications?date_from=${dateStart}&date_to=${dateEnd}`,
    headers()
  );
};

const updateProfile = (form) => {
  const formData = new FormData();
  formData.append("name", form.name);
  formData.append("phone", form.phone);
  formData.append("prodi_id", form.prodi_id);

  if (form.avatar !== null) {
    formData.append("avatar", form.avatar);
  }

  return axios.patch(
    `${process.env.REACT_APP_API}/profile/update-profile`,
    formData,
    headers(true)
  );
};

export { getNotifications, updateProfile };
