import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

// features
import { VerifyUser } from "features/authSlice";

const AuthorizeUser = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const [isRequested, setIsRequested] = useState(false);

  useEffect(() => {
    if (!user && !isRequested) {
      getUser();
      setIsRequested(true);
    }
  }, [user, isRequested]);

  const getUser = () => {
    dispatch(VerifyUser()).then(({ payload }) => {
      if (typeof payload == "string" || payload.status != "active") {
        navigate("/");
        return;
      }

      // Check role
      const role = payload.role.name;
      if (role === "mahasiswa" && !location.pathname.includes("mahasiswa"))
        navigate("/mahasiswa");
      else if (role === "dosen" && !location.pathname.includes("dosen"))
        navigate("/dosen");
      else if (role === "akademik" && !location.pathname.includes("akademik"))
        navigate("/sba");
      else if (role === "prodi" && !location.pathname.includes("prodi"))
        navigate("/prodi");
      else if (user.role.name === "admin") navigate("/admin/dashboard");
      navigate("/admin");
    });
  };

  return children;
};

export default AuthorizeUser;
