import axios from "axios";
import headers from "services/Headers";

export const createUser = (form) => {
  const formData = new FormData();
  formData.append("name", form.name);
  formData.append("email", form.email);
  formData.append("password", form.password);
  formData.append("phone", form.phone);
  formData.append("role_id", form.role_id);
  formData.append("prodi_id", form.prodi_id);
  formData.append("nip", form.nip);
  formData.append("avatar", form.avatar);

  return axios.post(
    `${process.env.REACT_APP_API}/admins/users`,
    formData,
    headers(true)
  );
};

export const updateUser = (form, id) => {
  const formData = new FormData();
  formData.append("name", form.name);
  formData.append("email", form.email);
  formData.append("phone", form.phone);
  formData.append("role_id", form.role_id);
  formData.append("prodi_id", form.prodi_id);
  formData.append("nip", form.nip);
  formData.append("avatar", form.avatar);

  if (form.password) {
    formData.append("password", form.password);
  }

  return axios.patch(
    `${process.env.REACT_APP_API}/admins/users/${id}`,
    formData,
    headers(true)
  );
};

export const getUsers = () => {
  return axios.get(`${process.env.REACT_APP_API}/admins/users`, headers());
};

export const destroyUser = (id) => {
  return axios.delete(
    `${process.env.REACT_APP_API}/admins/users/${id}`,
    headers()
  );
};

export const getRoles = () => {
  return axios.get(`${process.env.REACT_APP_API}/admins/roles`, headers());
};

export const createProdi = (form) => {
  return axios.post(`${process.env.REACT_APP_API}/prodis`, form, headers());
};

export const updateProdi = (form, id) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/prodis/${id}`,
    form,
    headers()
  );
};

export const getProdis = () => {
  return axios.get(`${process.env.REACT_APP_API}/prodis`, headers());
};

export const destroyProdi = (id) => {
  return axios.delete(`${process.env.REACT_APP_API}/prodis/${id}`, headers());
};
