import { useEffect, useState } from "react";
import moment from "moment";

// services
import { getNotifications } from "services/User";

// components
import TFade from "components/Transition/Fade";
import Header from "components/Header";
import ReservationsCalendar from "components/ReservationsCalendar";
import NotFound from "components/NotFound";
import ReservationItem from "components/ReservationItem";

// assets
import ic_calendar from "assets/icons/ic_calendar.svg";
import ic_full_screen from "assets/icons/ic_full_screen.svg";
import ic_notification from "assets/icons/ic_notification.svg";
import ic_megaphone_blue from "assets/icons/ic_megaphone_blue.svg";
import ic_time from "assets/icons/ic_time.svg";
import { getReservationsAdmin } from "services/Reservations";

const Dashboard = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [notifications, setNotifications] = useState([]);

  const [reservationsAccepted, setReservationsAccepted] = useState([]);
  const [reservationsCanceled, setReservationsCanceled] = useState([]);
  const [reservationsVerification1, setReservationsVerification1] = useState(
    []
  );
  const [reservationsVerification2, setReservationsVerification2] = useState(
    []
  );
  const [reservationsVerificationProdi, setReservationsVerificationProdi] =
    useState([]);
  const [reservationsVerificationSba, setReservationsVerificationSba] =
    useState([]);
  const [reservationsWaitingList, setReservationsWaitingList] = useState([]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setStartDate(new Date(currentYear, 0, 1));
    setEndDate(new Date(currentYear, 11, 31));
  }, []);

  useEffect(() => {
    getDataNotification();
    getDataReservation();
  }, [startDate, endDate]);

  const getDataNotification = async () => {
    try {
      const response = await getNotifications(startDate, endDate);
      setNotifications(response.data.body.data.notifications);
    } catch (error) {
      console.error(error);
    }
  };

  const getDataReservation = async () => {
    try {
      const response = await getReservationsAdmin();
      setReservationsAccepted(response.data.body.data.accepted);
      setReservationsCanceled(response.data.body.data.canceled);
      setReservationsVerification1(
        response.data.body.data.verification_examiner_1
      );
      setReservationsVerification2(
        response.data.body.data.verification_examiner_2
      );
      setReservationsVerificationProdi(
        response.data.body.data.verification_prodi
      );
      setReservationsVerificationSba(response.data.body.data.verification_sba);
      setReservationsWaitingList(response.data.body.data.waiting_list);
    } catch (error) {
      console.error(error);
    }
  };

  const openFullScreen = () => {
    const elem = document.documentElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  };

  return (
    <TFade>
      <div className="mb-5 flex flex-wrap items-center justify-between gap-3">
        <Header
          icon={<img src={ic_calendar} alt="Icon Calendar" />}
          title="Kalendar Reservasi Ruangan"
        />

        <div
          className="flex cursor-pointer items-center gap-3"
          onClick={openFullScreen}
        >
          <img src={ic_full_screen} alt="Icon Full Screen" />
          <span className="text-lg font-medium">Full Screen</span>
        </div>
      </div>

      {/* Calendar */}
      <ReservationsCalendar />

      <Header
        icon={<img src={ic_time} alt="Icon Time" />}
        title="Waiting List"
        className="mt-5"
      />

      {reservationsWaitingList.length > 0 ? (
        reservationsWaitingList.map((reservation, index) => (
          <ReservationItem data={reservation} key={`reservation-${index}`} />
        ))
      ) : (
        <NotFound />
      )}

      <Header
        icon={<img src={ic_time} alt="Icon Time" />}
        title="Menunggu Verifikasi Akademik"
        className="mt-5"
      />

      {reservationsVerificationSba.length > 0 ? (
        reservationsVerificationSba.map((reservation, index) => (
          <ReservationItem data={reservation} key={`reservation-${index}`} />
        ))
      ) : (
        <NotFound />
      )}

      <Header
        icon={<img src={ic_time} alt="Icon Time" />}
        title="Menunggu Verifikasi Prodi"
        className="mt-5"
      />

      {reservationsVerificationProdi.length > 0 ? (
        reservationsVerificationProdi.map((reservation, index) => (
          <ReservationItem data={reservation} key={`reservation-${index}`} />
        ))
      ) : (
        <NotFound />
      )}

      <Header
        icon={<img src={ic_time} alt="Icon Time" />}
        title="Menunggu Verifikasi Penguji 1"
        className="mt-5"
      />

      {reservationsVerification1.length > 0 ? (
        reservationsVerification1.map((reservation, index) => (
          <ReservationItem data={reservation} key={`reservation-${index}`} />
        ))
      ) : (
        <NotFound />
      )}

      <Header
        icon={<img src={ic_time} alt="Icon Time" />}
        title="Menunggu Verifikasi Penguji 2"
        className="mt-5"
      />

      {reservationsVerification2.length > 0 ? (
        reservationsVerification2.map((reservation, index) => (
          <ReservationItem data={reservation} key={`reservation-${index}`} />
        ))
      ) : (
        <NotFound />
      )}

      <Header
        icon={<img src={ic_time} alt="Icon Time" />}
        title="Jadwal Sidang Disetujui"
      />

      {reservationsAccepted.length > 0 ? (
        reservationsAccepted.map((reservation, index) => (
          <ReservationItem data={reservation} key={`reservation-${index}`} />
        ))
      ) : (
        <NotFound />
      )}

      <Header
        icon={<img src={ic_time} alt="Icon Time" />}
        title="Jadwal Sidang Tidak Disetujui"
      />

      {reservationsCanceled.length > 0 ? (
        reservationsCanceled.map((reservation, index) => (
          <ReservationItem data={reservation} key={`reservation-${index}`} />
        ))
      ) : (
        <NotFound />
      )}

      <Header
        icon={<img src={ic_notification} alt="Icon Notification" />}
        title="Notifikasi Terbaru"
        className="mt-5"
      />

      {notifications.length > 0 ? (
        notifications.map((notification, index) => (
          <div className="card my-5 flex !justify-between rounded-2xl">
            <div className="flex gap-3">
              <div className="icon !bg-[#00C2FF33]">
                <img src={ic_megaphone_blue} alt="Icon Megaphone" />
              </div>

              <div className="w-[75%]">
                <h5 className="text-xl font-medium">{notification.title}</h5>
                {/* <div className="mt-1 flex items-center gap-2 text-sm font-medium">
                  <img src={ic_user} alt="Icon User" />
                  Administrator
                </div> */}
              </div>
            </div>

            <span className="w-[20%] self-start text-lg font-medium text-primary-yellow">
              {moment().startOf("hour").fromNow()}
            </span>
          </div>
        ))
      ) : (
        <NotFound />
      )}
    </TFade>
  );
};

export default Dashboard;
