import moment from "moment";
import swal from "sweetalert2";
import axios from "axios";

export const strippedContent = (content, length = 40) => {
  let regex = /(<([^>]+)>)/gi;
  return (
    content.replace(regex, "").substring(0, length) +
    (content.length > length ? ".." : "")
  );
};

export const formatNumber = (number, isCurrency = true) => {
  var reverse = Math.round(number).toString().split("").reverse().join(""),
    ribuan = reverse.match(/\d{1,3}/g);

  // Jika mata uang pemisah memakai dot(.) jika bukan pemisah memakai comma(,)
  if (isCurrency) {
    ribuan = ribuan.join(".").split("").reverse().join("");
  } else {
    ribuan = ribuan.join(",").split("").reverse().join("");
  }

  return ribuan;
};

export const formatDate = (date, format) => {
  return moment(date).format(format);
};

export const confirmModal = () => {
  return swal.fire({
    title: "Apa anda yakin?",
    html: "Anda tidak akan dapat mengembalikan ini!",
    icon: "warning",
    confirmButtonText: "Ya",
    cancelButtonText: "Tidak",
    showCancelButton: true,
    buttonsStyling: false,
    customClass: {
      confirmButton:
        "button bg-primary-yellow text-white text-lg font-medium mr-2 !py-2 !px-6",
      cancelButton: "button bg-red text-white text-lg font-medium !py-2 !px-6",
    },
  });
};

export const successModal = (title, description) => {
  return swal.fire({
    title: title,
    html: description,
    icon: "success",
    buttonsStyling: false,
    customClass: {
      confirmButton:
        "button bg-green text-white text-lg font-medium !py-2 !px-6",
    },
  });
};

export const toCapitalizeEachWord = (sentence) => {
  const words = sentence.split(" ");

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }

  return words.join(" ");
};

export const toggleSidebar = () => {
  const sidebar = document.querySelector(".sidebar");
  const headingLogos = document.querySelectorAll(".sidebar-heading img");

  sidebar.classList.toggle("active");

  if (window.screen.width >= 1024) {
    if (!sidebar.classList.contains("active")) {
      headingLogos[0].classList.remove("active");
      headingLogos[1].classList.add("active");
    } else {
      setTimeout(() => {
        headingLogos[1].classList.remove("active");
        headingLogos[0].classList.add("active");
      }, 250);
    }
  }
};

export const getFaculties = () => {
  return axios.get(`${process.env.REACT_APP_API}/faculties`);
};

export const getProdis = () => {
  return axios.get(`${process.env.REACT_APP_API}/prodis`);
};

export const titleReservation = (reservation) => {
  // return `Sesi ${reservation.session} - Tanggal ${moment(
  //   reservation.date
  // ).format("DD MMMM YYYY")}`;
  const session =
    reservation.session == 0 ? "Waiting List" : `Sesi ${reservation.session}`;
  return `${session} - Tanggal ${moment(reservation.date).format(
    "DD MMMM YYYY"
  )}`;
};

export const dateTimeReservation = (date, session, isStart = true) => {
  switch (session) {
    case 1:
      if (isStart) return moment(`${date} 08:00`).toDate();
      else return moment(`${date} 10:00`).toDate();
    case 2:
      if (isStart) return moment(`${date} 10:00`).toDate();
      else return moment(`${date} 12:00`).toDate();
    case 3:
      if (isStart) return moment(`${date} 13:00`).toDate();
      else return moment(`${date} 15:00`).toDate();
    case 4:
      if (isStart) return moment(`${date} 15:00`).toDate();
      else return moment(`${date} 17:00`).toDate();
  }
};

export const descriptionReservation = (reservation) => {
  if (reservation.accepted === true) {
    return "Permintaan Reservasi Diterima";
  } else if (reservation.canceled === true) {
    return "Permintaan Reservasi Ditolak";
  } else if (
    reservation.verification_sba === false &&
    reservation.accepted === false &&
    reservation.canceled === false
  ) {
    return "Menunggu Verifikasi Dokumen oleh Akademik";
  } else if (
    reservation.verification_sba === true &&
    reservation.choose_lecture_prodi === false &&
    reservation.accepted === false &&
    reservation.canceled === false
  ) {
    return "Menunggu Prodi Memilih Dosen Penguji";
  } else if (
    reservation.verification_sba === true &&
    reservation.choose_lecture_prodi === true &&
    reservation.verification_lecture1 === false &&
    reservation.verification_lecture2 === false &&
    reservation.accepted === false &&
    reservation.canceled === false
  ) {
    return "Menunggu Dosen Penguji 1 dan Dosen Penguji 2 Menyetujui Permintaan Prodi";
  } else if (
    reservation.verification_sba === true &&
    reservation.choose_lecture_prodi === true &&
    reservation.verification_lecture1 === false &&
    reservation.verification_lecture2 === true &&
    reservation.accepted === false &&
    reservation.canceled === false
  ) {
    return "Menunggu Dosen Penguji 1 Menyetujui Permintaan Prodi";
  } else if (
    reservation.verification_sba === true &&
    reservation.choose_lecture_prodi === true &&
    reservation.verification_lecture1 === true &&
    reservation.verification_lecture2 === false &&
    reservation.accepted === false &&
    reservation.canceled === false
  ) {
    return "Menunggu Dosen Penguji 2 Menyetujui Permintaan Prodi";
  }
};

export const stepReservation = (reservation) => {
  if (reservation.accepted === true) {
    return 4;
  } else if (reservation.canceled === true) {
    return 5;
  } else if (
    reservation.verification_sba === false &&
    reservation.accepted === false &&
    reservation.canceled === false
  ) {
    return 1;
  } else if (
    reservation.verification_sba === true &&
    reservation.choose_lecture_prodi === false &&
    reservation.accepted === false &&
    reservation.canceled === false
  ) {
    return 2;
  } else if (
    reservation.verification_sba === true &&
    reservation.choose_lecture_prodi === true &&
    reservation.verification_lecture1 === false &&
    reservation.verification_lecture2 === false &&
    reservation.accepted === false &&
    reservation.canceled === false
  ) {
    return 3;
  } else if (
    reservation.verification_sba === true &&
    reservation.choose_lecture_prodi === true &&
    reservation.verification_lecture1 === false &&
    reservation.verification_lecture2 === true &&
    reservation.accepted === false &&
    reservation.canceled === false
  ) {
    return 3;
  } else if (
    reservation.verification_sba === true &&
    reservation.choose_lecture_prodi === true &&
    reservation.verification_lecture1 === true &&
    reservation.verification_lecture2 === false &&
    reservation.accepted === false &&
    reservation.canceled === false
  ) {
    return 3;
  }
};

export const StatusReservation = {
  accepted: 0,
  canceled: 1,
  pending: 2,
};

export const statusReservation = (reservation, role) => {
  if (role) {
    if (role == "sba" && reservation.verification_sba === true) {
      return StatusReservation.accepted;
    } else if (role == "prodi" && reservation.choose_lecture_prodi === true) {
      return StatusReservation.accepted;
    } else if (
      role == "dosen-penguji-1" &&
      reservation.verification_lecture1 === true
    ) {
      return StatusReservation.accepted;
    } else if (
      role == "dosen-penguji-2" &&
      reservation.verification_lecture2 === true
    ) {
      return StatusReservation.accepted;
    }
  }

  if (reservation.accepted === true) {
    return StatusReservation.accepted;
  } else if (reservation.canceled === true) {
    return StatusReservation.canceled;
  }

  return StatusReservation.pending;
};

export const renderDescriptionReservation = (data) => {
  switch (statusReservation(data)) {
    case StatusReservation.accepted:
      return (
        <span className="rounded bg-light-green px-2 py-1 text-sm text-accent-black">
          {descriptionReservation(data)}
        </span>
      );
    case StatusReservation.rejected:
      return (
        <span className="rounded bg-light-red px-2 py-1 text-sm text-red">
          {descriptionReservation(data)}
        </span>
      );
    case StatusReservation.pending:
      return (
        <span className="text-yellow rounded bg-light-yellow px-2 py-1 text-sm text-primary-yellow">
          {descriptionReservation(data)}
        </span>
      );
  }
};
