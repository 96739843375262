import { Link } from "react-router-dom";

// components
import Logo from "components/Logo";
import Copyright from "components/Copyright";

// assets
import logoAurora from "assets/images/logo-aurora.png";
import loadingBar from "assets/images/loading-bar.svg";
import ic_calendar from "assets/icons/ic_calendar.svg";
import ic_male from "assets/icons/ic_male.svg";
import ic_female from "assets/icons/ic_female.svg";

const Boarding = () => {
  return (
    <div className="bg-grid flex flex-col justify-between">
      {/* Navbar */}
      <nav className="mt-10 mb-5 flex justify-center lg:justify-between">
        <Logo />

        <div className="hidden gap-3 lg:flex">
          <a
            href="https://auroraweb.id/"
            target="_blank"
            className="card card-full card-icon mr-5"
          >
            <img src={logoAurora} alt="Aurora Web" />
          </a>

          <Link to="calendar" className="card card-full card-icon">
            <img src={ic_calendar} alt="Icon Calendar" />
          </Link>
        </div>
      </nav>

      {/* Jumbotron */}
      <div className="jumbotron flex flex-col items-center gap-4 text-center">
        <div>
          <div className="jumbotron-title relative">
            <h1 className="mx-3 text-4xl font-semibold">
              Sistem Reservasi Ruangan Sidang
            </h1>
            <span></span>
          </div>
          <span>
            Buat jadwal sidang kamu lebih mudah dengan{" "}
            <span className="font-medium">
              Sistem Reservasi Ruangan
              <br />
              Sidang Universitas Padjadjaran.
            </span>
          </span>
        </div>

        <img src={loadingBar} alt="Bar" />

        <span className="font-medium">Masuk Sebagai</span>

        <div className="grid items-center gap-5 md:grid-cols-2">
          <Link to="/login" className="card card-full gap-3 uppercase">
            <img src={ic_male} alt="Icon Male" />
            <div className="text-left">
              <h4 className="text-xl font-semibold">Mahasiswa</h4>
              <span className="text-sm">Pembuat Jadwal Sidang</span>
            </div>
          </Link>

          <Link to="/login-other" className="card card-full gap-3 uppercase">
            <img src={ic_female} alt="Icon Female" />
            <div className="text-left">
              <h4 className="text-xl font-semibold">Lainnya</h4>
              <span className="text-sm">dosen, akademik, prodi</span>
            </div>
          </Link>
        </div>
      </div>

      {/* Copyright */}
      <Copyright />
    </div>
  );
};

export default Boarding;
