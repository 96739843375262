import { useNavigate } from "react-router-dom";
import propTypes from "prop-types";

// icons
import ic_arrow_left from "assets/icons/ic_arrow_left.svg";

const ArrowBack = (props) => {
  const navigate = useNavigate();

  const click = () => {
    if (props.onClick) props.onClick();
    else navigate(-1);
  };

  return (
    <div onClick={click} className="flex cursor-pointer items-center gap-3">
      <img src={ic_arrow_left} alt="Icon Arrow Left" />
      <span className="text-xl font-medium">Kembali</span>
    </div>
  );
};

export default ArrowBack;

ArrowBack.propTypes = {
  onClick: propTypes.func,
};
