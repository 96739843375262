import { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";

// services
import { getReservationsCalendar } from "services/Reservations";
import { dateTimeReservation } from "services/general";
import Modal from "components/Modal";

const ReservationsCalendar = () => {
  const [schedules, setSchedules] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  // const getData = async () => {
  //   try {
  //     // const response = await getReservationsCalendar();
  //     // const data = response.data.body.data.result.process;
  //     // const newSchedules = [];
  //     // // [1,2,3,4]
  //     // for (var key in data) {
  //     //   for (var keySession in data[key]) {
  //     //     if (data[key][keySession].accepted) {
  //     //       const accepted = data[key][keySession].accepted;
  //     //       newSchedules.push({
  //     //         title: `Skripsi Diterima - ${accepted.title_skripsi_indonesia}`,
  //     //         start: dateTimeReservation(accepted.date, accepted.session),
  //     //         end: dateTimeReservation(accepted.date, accepted.session, false),
  //     //       });
  //     //       // newSchedules.push({
  //     //       //   title: `Lihat Jadwal`,
  //     //       //   start: dateTimeReservation(accepted.date, accepted.session),
  //     //       //   end: dateTimeReservation(accepted.date, accepted.session, false),
  //     //       //   id: accepted.id,
  //     //       // });
  //     //     }
  //     //     if (data[key][keySession].whitelist.length === 0) continue;
  //     //     data[key][keySession].whitelist.forEach((item) => {
  //     //       newSchedules.push({
  //     //         title: item.title_skripsi_indonesia,
  //     //         start: dateTimeReservation(item.date, item.session),
  //     //         end: dateTimeReservation(item.date, item.session, false),
  //     //       });
  //     //       // newSchedules.push({
  //     //       //   title: `Lihat Jadwal`,
  //     //       //   start: dateTimeReservation(item.date, item.session),
  //     //       //   end: dateTimeReservation(item.date, item.session, false),
  //     //       //   id: item.id,
  //     //       // });
  //     //     });
  //     //   }
  //     // }
  //     // setSchedules(newSchedules);

  //     // tolong buatkan kode untuk menampilkan schedule dari response json dibawah
  //     //   "header": {
  //     //     "time_request": "2024-07-27T01:45:03.380Z",
  //     //     "ip_address": "::1"
  //     //   },
  //     //   "body": {
  //     //     "status": true,
  //     //     "message": "Success get calendar reservation.",
  //     //     "data": {
  //     //       "result": {
  //     //         "waiting_list": {},
  //     //         "process": {
  //     //           "2024-07-26": {
  //     //             "1": [
  //     //               {
  //     //                 "id": "RSV-69668640-4b23-11ef-a629-39766c42bb30",
  //     //                 "student_id": "USR-6c386310-4511-11ef-bbff-fb06379e80a8",
  //     //                 "date": "2024-07-26",
  //     //                 "session": 1,
  //     //                 "name_student": "addad",
  //     //                 "npm_student": "121212212121",
  //     //                 "height_student": 0,
  //     //                 "address_student": "121221",
  //     //                 "phone_student": "1212",
  //     //                 "date_start_guidance": "2024-07-26",
  //     //                 "date_end_guidance": "2024-07-26",
  //     //                 "prodi_lecture_guidance": "PRD-35870d30-e2e7-11ee-8c3c-b9a8c6f4cc61",
  //     //                 "lecture_guidance": "USR-cea7bca0-450f-11ef-bbff-fb06379e80a8",
  //     //                 "title_skripsi_indonesia": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
  //     //                 "title_skripsi_english": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
  //     //                 "surat_tugas_jadwal_bimbingan": "6963c720-4b23-11ef-a629-39766c42bb30.pdf",
  //     //                 "lembar_validasi_similarity": "6963c721-4b23-11ef-a629-39766c42bb30.pdf",
  //     //                 "transkrip_akademik": "6963ee30-4b23-11ef-a629-39766c42bb30.pdf",
  //     //                 "bukti_pembayaran_kuliah": "6963ee31-4b23-11ef-a629-39766c42bb30.pdf",
  //     //                 "krs_terakhir": "69641540-4b23-11ef-a629-39766c42bb30.pdf",
  //     //                 "draft_skripsi": "69641541-4b23-11ef-a629-39766c42bb30.pdf",
  //     //                 "file_presentasi": "69641542-4b23-11ef-a629-39766c42bb30.pdf",
  //     //                 "lembar_selesai_revisi": "69641543-4b23-11ef-a629-39766c42bb30.pdf",
  //     //                 "foto_bukti_seminar": "69643c50-4b23-11ef-a629-39766c42bb30.pdf",
  //     //                 "pas_photo_4x6": "69646360-4b23-11ef-a629-39766c42bb30.png",
  //     //                 "pas_photo_3x4": "69646361-4b23-11ef-a629-39766c42bb30.png",
  //     //                 "pas_photo_2x3": "69646362-4b23-11ef-a629-39766c42bb30.png",
  //     //                 "ktm": "69646363-4b23-11ef-a629-39766c42bb30.pdf",
  //     //                 "toefl": "69646364-4b23-11ef-a629-39766c42bb30.pdf",
  //     //                 "surat_bebas_pinjam_buku_universitas": "69646365-4b23-11ef-a629-39766c42bb30.pdf",
  //     //                 "surat_bebas_pinjam_buku_fakultas": "69648a70-4b23-11ef-a629-39766c42bb30.pdf",
  //     //                 "verification_sba": true,
  //     //                 "choose_lecture_prodi": true,
  //     //                 "examiner1": "USR-960b71a0-4b1a-11ef-a629-39766c42bb30",
  //     //                 "examiner2": "USR-960b71a0-4b1a-11ef-a629-39766c42bb30",
  //     //                 "verification_lecture1": true,
  //     //                 "verification_lecture2": true,
  //     //                 "accepted": true,
  //     //                 "canceled": false,
  //     //                 "message_cancel": null,
  //     //                 "createdAt": "2024-07-26T07:48:13.000Z",
  //     //                 "updatedAt": "2024-07-26T15:57:20.000Z",
  //     //                 "student": {
  //     //                   "id": "USR-6c386310-4511-11ef-bbff-fb06379e80a8",
  //     //                   "name": "Ariq TesTesTesTesTesTesTesTesTesTesTesTes",
  //     //                   "email": "ahidayatbia@gmail.com",
  //     //                   "phone": "0812121211221",
  //     //                   "password": "$2a$10$Sxp/Ej3fEGMhr5bVoOGIk.B5NhWl5VxrZHDiyYk5eEqOY5jmcP2n2",
  //     //                   "role_id": "RL-34f77760-e2e7-11ee-8c3c-b9a8c6f4cc61",
  //     //                   "faculty_id": "FCLTY-35142720-e2e7-11ee-8c3c-b9a8c6f4cc61",
  //     //                   "prodi_id": "PRD-35870d31-e2e7-11ee-8c3c-b9a8c6f4cc61",
  //     //                   "avatar": "6c232d60-4511-11ef-bbff-fb06379e80a8.png",
  //     //                   "npm": "121212121212",
  //     //                   "nip": null,
  //     //                   "status": "active",
  //     //                   "deleted": false,
  //     //                   "createdAt": "2024-07-18T14:24:20.000Z",
  //     //                   "updatedAt": "2024-07-26T07:45:34.000Z",
  //     //                   "role": {
  //     //                     "id": "RL-34f77760-e2e7-11ee-8c3c-b9a8c6f4cc61",
  //     //                     "name": "mahasiswa",
  //     //                     "createdAt": "2024-03-15T16:15:14.000Z",
  //     //                     "updatedAt": "2024-03-15T16:15:14.000Z"
  //     //                   },
  //     //                   "faculty": {
  //     //                     "id": "FCLTY-35142720-e2e7-11ee-8c3c-b9a8c6f4cc61",
  //     //                     "name": "Ekonomi & Bisnis",
  //     //                     "createdAt": "2024-03-15T16:15:15.000Z",
  //     //                     "updatedAt": "2024-07-17T09:52:14.000Z"
  //     //                   },
  //     //                   "prodi": {
  //     //                     "id": "PRD-35870d31-e2e7-11ee-8c3c-b9a8c6f4cc61",
  //     //                     "name": "Ilmu Ekonomi",
  //     //                     "faculty_id": "FCLTY-35142720-e2e7-11ee-8c3c-b9a8c6f4cc61",
  //     //                     "createdAt": "2024-03-15T16:15:15.000Z",
  //     //                     "updatedAt": "2024-07-17T09:52:38.000Z"
  //     //                   }
  //     //                 },
  //     //                 "lecture_guide": {
  //     //                   "id": "USR-cea7bca0-450f-11ef-bbff-fb06379e80a8",
  //     //                   "name": "dosen tes",
  //     //                   "email": "dosen.tes@gmail.com",
  //     //                   "phone": "0812121212",
  //     //                   "password": "$2a$10$vlHeyWkfn6PAOf7afzUOqu0eE30GIl2g3zCp02y8FWh2k9QzhBHLa",
  //     //                   "role_id": "RL-34f7c580-e2e7-11ee-8c3c-b9a8c6f4cc61",
  //     //                   "faculty_id": "FCLTY-35142720-e2e7-11ee-8c3c-b9a8c6f4cc61",
  //     //                   "prodi_id": "PRD-35870d30-e2e7-11ee-8c3c-b9a8c6f4cc61",
  //     //                   "avatar": "ce84f260-450f-11ef-bbff-fb06379e80a8.png",
  //     //                   "npm": null,
  //     //                   "nip": "12121212121",
  //     //                   "status": "active",
  //     //                   "deleted": false,
  //     //                   "createdAt": "2024-07-18T14:12:46.000Z",
  //     //                   "updatedAt": "2024-07-18T14:12:46.000Z",
  //     //                   "role": {
  //     //                     "id": "RL-34f7c580-e2e7-11ee-8c3c-b9a8c6f4cc61",
  //     //                     "name": "dosen",
  //     //                     "createdAt": "2024-03-15T16:15:14.000Z",
  //     //                     "updatedAt": "2024-03-15T16:15:14.000Z"
  //     //                   },
  //     //                   "faculty": {
  //     //                     "id": "FCLTY-35142720-e2e7-11ee-8c3c-b9a8c6f4cc61",
  //     //                     "name": "Ekonomi & Bisnis",
  //     //                     "createdAt": "2024-03-15T16:15:15.000Z",
  //     //                     "updatedAt": "2024-07-17T09:52:14.000Z"
  //     //                   },
  //     //                   "prodi": {
  //     //                     "id": "PRD-35870d30-e2e7-11ee-8c3c-b9a8c6f4cc61",
  //     //                     "name": "Ekonomi Islam",
  //     //                     "faculty_id": "FCLTY-35142720-e2e7-11ee-8c3c-b9a8c6f4cc61",
  //     //                     "createdAt": "2024-03-15T16:15:15.000Z",
  //     //                     "updatedAt": "2024-07-17T09:52:38.000Z"
  //     //                   }
  //     //                 },
  //     //                 "examiner_1": {
  //     //                   "id": "USR-960b71a0-4b1a-11ef-a629-39766c42bb30",
  //     //                   "name": "Dosen Tes 2",
  //     //                   "email": "dosen2@gmail.com",
  //     //                   "phone": "0812121",
  //     //                   "password": "$2a$10$Un1u08ky49ZdRZZm0Q.K7OvBeXOh2nw9VzrrjbiNYyKgIj6TYVaTm",
  //     //                   "role_id": "RL-34f7c580-e2e7-11ee-8c3c-b9a8c6f4cc61",
  //     //                   "faculty_id": "FCLTY-35142720-e2e7-11ee-8c3c-b9a8c6f4cc61",
  //     //                   "prodi_id": "PRD-35870d30-e2e7-11ee-8c3c-b9a8c6f4cc61",
  //     //                   "avatar": "95f810b0-4b1a-11ef-a629-39766c42bb30.png",
  //     //                   "npm": null,
  //     //                   "nip": "12121",
  //     //                   "status": "active",
  //     //                   "deleted": false,
  //     //                   "createdAt": "2024-07-26T06:45:03.000Z",
  //     //                   "updatedAt": "2024-07-26T06:45:03.000Z",
  //     //                   "role": {
  //     //                     "id": "RL-34f7c580-e2e7-11ee-8c3c-b9a8c6f4cc61",
  //     //                     "name": "dosen",
  //     //                     "createdAt": "2024-03-15T16:15:14.000Z",
  //     //                     "updatedAt": "2024-03-15T16:15:14.000Z"
  //     //                   },
  //     //                   "faculty": {
  //     //                     "id": "FCLTY-35142720-e2e7-11ee-8c3c-b9a8c6f4cc61",
  //     //                     "name": "Ekonomi & Bisnis",
  //     //                     "createdAt": "2024-03-15T16:15:15.000Z",
  //     //                     "updatedAt": "2024-07-17T09:52:14.000Z"
  //     //                   },
  //     //                   "prodi": {
  //     //                     "id": "PRD-35870d30-e2e7-11ee-8c3c-b9a8c6f4cc61",
  //     //                     "name": "Ekonomi Islam",
  //     //                     "faculty_id": "FCLTY-35142720-e2e7-11ee-8c3c-b9a8c6f4cc61",
  //     //                     "createdAt": "2024-03-15T16:15:15.000Z",
  //     //                     "updatedAt": "2024-07-17T09:52:38.000Z"
  //     //                   }
  //     //                 },
  //     //                 "examiner_2": {
  //     //                   "id": "USR-960b71a0-4b1a-11ef-a629-39766c42bb30",
  //     //                   "name": "Dosen Tes 2",
  //     //                   "email": "dosen2@gmail.com",
  //     //                   "phone": "0812121",
  //     //                   "password": "$2a$10$Un1u08ky49ZdRZZm0Q.K7OvBeXOh2nw9VzrrjbiNYyKgIj6TYVaTm",
  //     //                   "role_id": "RL-34f7c580-e2e7-11ee-8c3c-b9a8c6f4cc61",
  //     //                   "faculty_id": "FCLTY-35142720-e2e7-11ee-8c3c-b9a8c6f4cc61",
  //     //                   "prodi_id": "PRD-35870d30-e2e7-11ee-8c3c-b9a8c6f4cc61",
  //     //                   "avatar": "95f810b0-4b1a-11ef-a629-39766c42bb30.png",
  //     //                   "npm": null,
  //     //                   "nip": "12121",
  //     //                   "status": "active",
  //     //                   "deleted": false,
  //     //                   "createdAt": "2024-07-26T06:45:03.000Z",
  //     //                   "updatedAt": "2024-07-26T06:45:03.000Z",
  //     //                   "role": {
  //     //                     "id": "RL-34f7c580-e2e7-11ee-8c3c-b9a8c6f4cc61",
  //     //                     "name": "dosen",
  //     //                     "createdAt": "2024-03-15T16:15:14.000Z",
  //     //                     "updatedAt": "2024-03-15T16:15:14.000Z"
  //     //                   },
  //     //                   "faculty": {
  //     //                     "id": "FCLTY-35142720-e2e7-11ee-8c3c-b9a8c6f4cc61",
  //     //                     "name": "Ekonomi & Bisnis",
  //     //                     "createdAt": "2024-03-15T16:15:15.000Z",
  //     //                     "updatedAt": "2024-07-17T09:52:14.000Z"
  //     //                   },
  //     //                   "prodi": {
  //     //                     "id": "PRD-35870d30-e2e7-11ee-8c3c-b9a8c6f4cc61",
  //     //                     "name": "Ekonomi Islam",
  //     //                     "faculty_id": "FCLTY-35142720-e2e7-11ee-8c3c-b9a8c6f4cc61",
  //     //                     "createdAt": "2024-03-15T16:15:15.000Z",
  //     //                     "updatedAt": "2024-07-17T09:52:38.000Z"
  //     //                   }
  //     //                 }
  //     //               }
  //     //             ],
  //     //             "2": [],
  //     //             "3": [],
  //     //             "4": []
  //     //           }
  //     //         }
  //     //       }
  //     //     }
  //     //   }
  //     // }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const getData = async () => {
    try {
      const response = await getReservationsCalendar();
      const data = response.data.body.data.result.process;
      const newSchedules = [];

      for (const date in data) {
        for (const session in data[date]) {
          data[date][session].forEach((item) => {
            if (item.accepted) {
              newSchedules.push({
                title: item.title_skripsi_indonesia,
                start: dateTimeReservation(item.date, item.session),
                end: dateTimeReservation(item.date, item.session, false),
              });
            }
            if (item.whitelist && item.whitelist.length > 0) {
              item.whitelist.forEach((whitelistItem) => {
                newSchedules.push({
                  title: `Waiting List - ${whitelistItem.title_skripsi_indonesia}`,
                  start: dateTimeReservation(
                    whitelistItem.date,
                    whitelistItem.session
                  ),
                  end: dateTimeReservation(
                    whitelistItem.date,
                    whitelistItem.session,
                    false
                  ),
                });
              });
            }
          });
        }
      }

      setSchedules(newSchedules);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="card relative !block rounded-2xl">
        <FullCalendar
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          plugins={[dayGridPlugin, timeGridPlugin]}
          views={["dayGridMonth", "dayGridWeek", "dayGridDay"]}
          events={schedules}
          eventContent={renderEventContent}
          // eventClick={(info) => setShowModal(true)}
        />
      </div>
      <Modal
        name="Detail Jadwal"
        showModal={showModal}
        setShowModal={(value) => setShowModal(value)}
        body={<div></div>}
      />
    </>
  );
};

function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  );
}

export default ReservationsCalendar;
