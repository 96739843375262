import { useEffect, useState } from "react";
import moment from "moment";

// services
import { getNotifications } from "services/User";

// components
import TFade from "components/Transition/Fade";
import InputDate from "components/Form/InputDate";
import NotFound from "components/NotFound";

// assets
import ic_megaphone_blue from "assets/icons/ic_megaphone_blue.svg";

const Notification = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setStartDate(new Date(currentYear, 0, 1));
    setEndDate(new Date(currentYear, 11, 31));
  }, []);

  useEffect(() => {
    getData();
  }, [startDate, endDate]);

  const getData = async () => {
    try {
      const response = await getNotifications(startDate, endDate);
      setNotifications(response.data.body.data.notifications);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <TFade>
      <div className="mb-5 grid grid-cols-2 gap-5">
        <div>
          <h5 className="mb-3 text-lg font-medium">Tanggal Awal Filter</h5>

          <InputDate
            name="startDate"
            value={startDate}
            placeholder="Tanggal Awal Filter"
            onChange={(value) => setStartDate(value)}
          />
        </div>

        <div>
          <h5 className="mb-3 text-lg font-medium">Tanggal Akhir Filter</h5>

          <InputDate
            name="endDate"
            value={endDate}
            placeholder="Tanggal Akhir Filter"
            onChange={(value) => setEndDate(value)}
          />
        </div>
      </div>

      {notifications.length > 0 ? (
        notifications.map((notification, index) => (
          <div className="card my-5 flex !justify-between rounded-2xl">
            <div className="flex gap-3">
              <div className="icon !bg-[#00C2FF33]">
                <img src={ic_megaphone_blue} alt="Icon Megaphone" />
              </div>

              <div className="w-[75%]">
                <h5 className="text-xl font-medium">{notification.title}</h5>
                {/* <div className="mt-1 flex items-center gap-2 text-sm font-medium">
                  <img src={ic_user} alt="Icon User" />
                  Administrator
                </div> */}
              </div>
            </div>

            <span className="w-[20%] self-start text-lg font-medium text-primary-yellow">
              {moment().startOf("hour").fromNow()}
            </span>
          </div>
        ))
      ) : (
        <NotFound />
      )}
    </TFade>
  );
};

export default Notification;
